//INIT
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"

//FUNCTIONS \ REQUESTS

import { buscarestabelecimentos } from "../../services/integracao"

import {} from "../../pages/RegistradorDePedidos/functions"

import {} from "../../services/functionsGerais"

import {} from "../../services/comum"

import { ObterCardapio } from "../../services/produto"

import {} from "../../services/cliente"

//FILES
import Cabecalho from "../Cabecalho"
import Loading from "../Componentes/Loading"
import MostrarEstabelecimentos from "../Componentes/MostrarEstabelecimentos"
import DialogCardapio from "./DialogCardapio"
import "./styles.css"

//FRAMEWORK
import Container from "@material-ui/core/Container"
import Snackbar from "@material-ui/core/Snackbar"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import MuiAlert from "@material-ui/lab/Alert"
import { Col, Row } from "react-bootstrap"
import { RegistrarLogErro } from "../../services/clientefiel"

const useStyles = makeStyles(theme => ({}))

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

export default function Cardapio() {
    const aplicativoDados = JSON.parse(localStorage.getItem("aplicativoCF"))
    const autorizacao = localStorage.getItem("chaveSistema")
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
    )

    //imports
    const theme = useTheme()
    const history = useHistory()
    const classes = useStyles()

    //states
    const [alert, setAlert] = useState({ status: false, tipo: "success", mesangem: "" })

    const [state, setState] = useState({
        estabelecimentos: [],
        estabelecimento: {},
        loading: true,
        cardapio: [],
    })

    console.log("state", state)

    const { estabelecimento, estabelecimentos, loading, categoriaEdit, produtoEdit, cardapio } =
        state

    const alertStart = (msg, tipo) => {
        setAlert({ status: true, tipo: tipo, mesangem: msg })
    }

    const alertClose = (event, reason) => {
        if (reason === "clickaway") {
            return
        }
        setAlert({ status: false, tipo: alert.tipo, mesangem: alert.mesangem })
    }

    const handleBuscarDadosEstabelecimentos = async () => {
        let estabelecimentos = []
        let estabelecimento = {}

        try {
            const response = await buscarestabelecimentos(autorizacao)
            console.log("Parametros", response)

            if (response.retornoErro) {
                alertStart(response.mensagem, "error")

                setState({
                    ...state,
                    loading: false,
                })
            } else {
                estabelecimentos = response

                // if(response.length == 1){
                // if(true){
                //   estabelecimento = response[0]
                //   handleBuscarCardapio(estabelecimento, estabelecimentos)
                //   return true
                // }

                setState({
                    ...state,
                    estabelecimentos: estabelecimentos,
                    estabelecimento: estabelecimento,
                    loading: false,
                })
            }
        } catch (error) {
            RegistrarLogErro(error, "handleBuscarDadosEstabelecimentos")
        }
        return {}
    }

    const handleBuscarCardapio = async (estabelecimento, estabelecimentos) => {
        try {
            console.log("handle buscar")
            setState({ ...state, loading: true, estabelecimentos: estabelecimentos })

            let cardapio = []

            const response = await ObterCardapio(autorizacao, estabelecimento)

            if (response.retornoErro) {
                alertStart(response.mensagem, "error")
                estabelecimento = {}
            } else {
                cardapio = response
            }

            setState({
                ...state,
                loading: false,
                cardapio: cardapio,
                estabelecimento: estabelecimento,
            })
        } catch (error) {
            RegistrarLogErro(error, "handleBuscarCardapio", estabelecimento)
        }
    }

    const handleSalvar = () => {
        setState({
            ...state,
            loading: true,
            cardapio: [],
            estabelecimento: {},
        })

        window.setTimeout(function () {
            setState({
                ...state,
                loading: false,
                cardapio: [],
                estabelecimento: {},
            })
        }, 1000)
    }

    useEffect(() => {
        handleBuscarDadosEstabelecimentos()
    }, [])

    return (
        <Container component="main" style={{ marginTop: "6em" }} className="container-buscando">
            <Cabecalho pagina={"Edição de Cardápio"} />

            <Loading loading={loading} />

            <Snackbar open={alert.status} autoHideDuration={6000} onClose={alertClose}>
                <Alert onClose={alertClose} severity={alert.tipo}>
                    {alert.mesangem}
                </Alert>
            </Snackbar>

            <Row style={{ textAlign: "center" }}>
                <Col xs={12} md={12} lg={12} style={{ margin: "1em 0" }}>
                    {!estabelecimento.id && estabelecimentos.length ? (
                        <MostrarEstabelecimentos
                            classes={classes}
                            estabelecimentos={estabelecimentos}
                            handle={handleBuscarCardapio}
                        />
                    ) : null}

                    {estabelecimento.id && cardapio.length ? (
                        <DialogCardapio
                            theme={theme}
                            open={true}
                            setOpen={handleSalvar}
                            estabelecimento={estabelecimento}
                            state={state}
                            setState={setState}
                        />
                    ) : null}
                </Col>
            </Row>
        </Container>
    )
}
