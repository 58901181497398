import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import React from "react"

import { makeStyles } from "@material-ui/core/styles"

import useMediaQuery from "@material-ui/core/useMediaQuery"

import FormControlLabel from "@material-ui/core/FormControlLabel"

import Switch from "@material-ui/core/Switch"
import ConfiguracoesBasicas_categoria from "./ConfiguracoesBasicas_categoria"

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
        width: "100%",
    },
    rootcategoria: {
        // maxWidth: 345,
    },
    categoria: {
        margin: "0px!important",
        alignItems: "center",
    },
    ativo: {},
    inativo: {
        color: "#dc3545",
    },
    descricao: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        // width: "100%!important",
        display: "block",
        width: "95%",
    },
    margin: {
        // margin: theme.spacing(1),
        // padding: '10px',
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    },
    textField: {
        background: "#f9f9f9",
        // width: '7em',
    },
    padding: {
        padding: "10px",
    },
    listItem: {
        display: "flex",
        alignItems: "center",
    },
    fullSize: {
        padding: "4px 200px 4px 16px",
    },
    smallSize: {
        padding: "4px 4px 4px 16px",
    },
    colOptions: {
        display: "flex",
        margin: "0.5em 0px",
        justifyContent: "center",
        alignItems: "center",
    },
    buttonAdicionarCategoria: {},
    buttonListAdicionarItem: {
        justifyContent: "center",
    },
    buttonAdicionarItem: {
        color: "#dc3545",
    },
}))

function getMoney(str) {
    console.log("str", str)
    var value = str.replace(/[\D]+/g, "")
    if (value == "") {
        return value
    }
    return parseInt(value)
}
function formatReal(int) {
    if (int == "") {
        return "0"
    }

    console.log("int", int)
    var tmp = int + ""
    tmp = tmp.replace(/([0-9]{2})$/g, ",$1")
    if (tmp.length > 6) tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2")

    return tmp
}

export default function Dialogcategoria(props) {
    const classes = useStyles()

    const { theme, state, categoria, setState } = props

    const smallSize = useMediaQuery(theme.breakpoints.down("sm"))
    const [value, setValue] = React.useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const handleClose = async () => {
        // setCategoria(null);

        const ordem = document.getElementById("categoria_ordem_exibicao").value
        const nome = document.getElementById("categoria_nome").value
        const dia = document.getElementById("categoria_dia_disponivel").value
        const turno = document.getElementById("categoria_turno_disponivel").value
        const exibicao = document.getElementById("categoria_exibicao").value

        await state.cardapio.forEach(c => {
            if (c.id == categoria.id) {
                c.nome = nome
                c.ordemExib = ordem
                c.diaSemana = dia
                c.turnoProduto = turno
                c.exibicaoCategoriaEnum = exibicao
            }
        })

        await state.cardapio.sort(function (a, b) {
            if (a.ordemExib > b.ordemExib) {
                return 1
            }
            if (a.ordemExib < b.ordemExib) {
                return -1
            }
            return 0
        })

        setState({
            ...state,
            categoriaEdit: null,
        })
    }

    const changeValuePrice = e => {
        var value = formatReal(getMoney(e.target.value))
        e.target.value = value
    }

    return (
        <div>
            {!!categoria && (
                <Dialog
                    open={!!categoria}
                    fullScreen={false}
                    onClose={handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">
                        {categoria.nome}
                        <FormControlLabel
                            style={{
                                margin: "0 0 0 5px",
                                float: "right",
                            }}
                            control={
                                <Switch checked={categoria.ativo} color="primary" name="checkedA" />
                            }
                        />
                    </DialogTitle>

                    {/* <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              centered
            >
              <Tab label="Básicas" />
              <Tab label="Avançadas" />
            </Tabs>
          </AppBar> */}

                    <DialogContent>
                        {
                            <ConfiguracoesBasicas_categoria
                                categoria={categoria}
                                setState={setState}
                            />
                        }
                    </DialogContent>
                </Dialog>
            )}
        </div>
    )
}
