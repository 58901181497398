import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Select from "@material-ui/core/Select"
import TextField from "@material-ui/core/TextField"
import React from "react"

import { makeStyles } from "@material-ui/core/styles"

import Accordion from "@material-ui/core/Accordion"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

import FormControl from "@material-ui/core/FormControl"
import IconButton from "@material-ui/core/IconButton"
import InputAdornment from "@material-ui/core/InputAdornment"
import InputLabel from "@material-ui/core/InputLabel"
import clsx from "clsx"

import { Col, Row } from "react-bootstrap"

import useMediaQuery from "@material-ui/core/useMediaQuery"

import FormControlLabel from "@material-ui/core/FormControlLabel"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import ListItemText from "@material-ui/core/ListItemText"
import SettingsIcon from "@material-ui/icons/Settings"

import AddIcon from "@material-ui/icons/Add"
import SaveIcon from "@material-ui/icons/Save"

import Comida from "../../assets/comida.svg"
import DialogCategoria from "./DialogCategoria"
import DialogProduto from "./DialogProduto"

import Switch from "@material-ui/core/Switch"

import { CadastrarCategoriaProduto, CadastrarProduto } from "../../services/produto"

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
        width: "100%",
    },
    rootProduto: {
        // maxWidth: 345,
    },
    categoria: {
        margin: "0px!important",
        alignItems: "center",
    },
    ativo: {},
    inativo: {
        color: "#dc3545",
    },
    descricao: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        // width: "100%!important",
        display: "block",
        width: "95%",
    },
    margin: {
        // margin: theme.spacing(1),
        // padding: '10px',
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    },
    textField: {
        background: "#f9f9f9",
        width: "8em",
    },
    padding: {
        padding: "10px",
    },
    listItem: {
        display: "flex",
        alignItems: "center",
    },
    fullSize: {
        padding: "4px 200px 4px 16px",
    },
    smallSize: {
        padding: "4px 4px 4px 16px",
    },
    colOptions: {
        display: "flex",
        margin: "0.5em 0px",
        justifyContent: "center",
        alignItems: "center",
    },
    colOptionsStart: {
        display: "flex",
        margin: "0.5em 0px",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    colOptionsEnd: {
        display: "flex",
        margin: "0.5em 0px",
        justifyContent: "flex-end",
        alignItems: "center",
    },
    buttonAdicionarCategoria: {
        // margin: theme.spacing(1),
        // background: '#dc3545',
        // color: 'white',f
        // position: 'relative',
        // float: 'right',
    },
    buttonListAdicionarItem: {
        justifyContent: "center",
    },
    buttonAdicionarItem: {
        color: "#dc3545",
    },
}))

export default function DialogCardapio(props) {
    const classes = useStyles()

    const { theme, open, setOpen, estabelecimento, state, setState } = props

    const smallSize = useMediaQuery(theme.breakpoints.down("sm"))

    const [produtoEdit, setProdutoEdit] = React.useState(null)
    const [categoriaEdit, setCategoriaEdit] = React.useState(null)
    const { cardapio } = state

    const handleAdicionarCategoria = async () => {
        try {
            setState({
                ...state,
                loading: true,
            })

            const data = {
                nome: "Nova Categoria",
                ordemExib: 0,
                token: "a~I?;;+_@!AL^",
                produtos: [],
                estabelecimento: estabelecimento.id,
            }
            const response = await CadastrarCategoriaProduto(data)
            if (response.retornoErro) {
            } else {
                setTimeout(() => {
                    setState({
                        ...state,
                        loading: false,
                        cardapio: [response].concat(cardapio),
                        categoriaEdit: response,
                    })
                }, 1000)
            }
        } catch (error) {}
    }

    const handleEditProduto = produto => {
        setProdutoEdit(produto)
    }
    const handleEditCategoria = categoria => {
        // setCategoriaEdit(categoria)
        setState({
            ...state,
            categoriaEdit: categoria,
        })
    }

    const handleAdicionarProduto = async (categoriaIndex, categoriaId) => {
        try {
            setState({
                ...state,
                loading: true,
            })

            const data = {
                nome: "Novo Produto",
                ativo: true,
                token: "a~I?;;+_@!AL^",
                opcoes: [],
                ordemExib: 0,
                idCategoria: categoriaId,
                apenasPrimeiraCompra: false,
                permiteObservacao: true,
                produtoExibicao: false,
                promocional: false,
                qntMaximaProdutos: 0,
                qntMinimaProdutos: 0,
                valor: 0,
            }

            const response = await CadastrarProduto(data)
            if (response.retornoErro) {
            } else {
                const newProdutos = [response].concat(cardapio[categoriaIndex].produtos)
                cardapio[categoriaIndex].produtos = newProdutos
                setTimeout(() => {
                    setState({
                        ...state,
                        loading: false,
                        cardapio: JSON.parse(JSON.stringify(cardapio)),
                        produtoEdit: response,
                    })
                }, 1000)
            }
        } catch (error) {}
    }

    const handleAtivamento = (indexC, indexP) => {
        const newCardapio = JSON.parse(JSON.stringify(cardapio))
        newCardapio[indexC].produtos[indexP].ativo = !newCardapio[indexC].produtos[indexP].ativo
        setState({
            ...state,
            cardapio: newCardapio,
        })
    }

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const [modoEdicao, setModoEdicao] = React.useState("basico")
    const [timeDisabled, setTimeDisabled] = React.useState(false)

    return (
        <div>
            {open && (
                <Dialog
                    open={open}
                    onClose={handleClose}
                    fullScreen
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">
                        <Row>
                            <Col xs={6} md={6} lg={6} className={classes.colOptionsStart}>
                                Cardápio {estabelecimento.nome}
                            </Col>
                            <Col xs={6} md={6} lg={6} className={classes.colOptionsEnd}>
                                <FormControl
                                    variant="outlined"
                                    size="small"
                                    className={classes.formControl}
                                    style={{ marginRight: "1em" }}
                                >
                                    <InputLabel htmlFor="tipoAdicional">Modo Edição</InputLabel>
                                    <Select
                                        native
                                        fullWidth
                                        size="small"
                                        value={modoEdicao}
                                        label="Modo Edição"
                                        onChange={e => {
                                            setModoEdicao(e.target.value)
                                        }}
                                        className={clsx(classes.margin, classes.textField)}
                                        inputProps={{
                                            name: "Modo Edição",
                                            id: "modoEdicao",
                                        }}
                                    >
                                        <option value={"basico"}>Básico</option>
                                        <option value={"avancado"}>Avançado</option>
                                        {/* <option value={'adicionais'}>Adicionais</option> */}
                                        {/* <option value={'pdv'}>PDV</option> */}
                                        {/* <option value={'importadorFotos'}>Importador de Fotos</option> */}
                                    </Select>
                                </FormControl>

                                <Button variant="contained" startIcon={<SaveIcon />}>
                                    Salvo
                                </Button>
                            </Col>
                        </Row>
                    </DialogTitle>
                    <DialogContent>
                        {/* <div className={classes.root}> */}
                        <Row>
                            <Col xs={12} md={12} lg={12} className={classes.colOptions}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={timeDisabled}
                                    onClick={handleAdicionarCategoria}
                                    startIcon={<AddIcon />}
                                >
                                    Adicionar Categoria
                                </Button>
                            </Col>
                            {cardapio.map((categoria, indexC) => (
                                <Col xs={12} md={12} lg={6} style={{ margin: "0.5em 0" }}>
                                    <Accordion key={indexC}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            classes={{
                                                content: classes.categoria,
                                                expanded: classes.categoria,
                                            }}
                                        >
                                            <Typography className={classes.heading}>
                                                {categoria.ordemExib} - {categoria.nome}
                                            </Typography>
                                            <IconButton
                                                edge="end"
                                                onClick={e => {
                                                    handleEditCategoria(categoria)
                                                    e.stopPropagation()
                                                }}
                                                aria-label="edit"
                                            >
                                                <SettingsIcon />
                                            </IconButton>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <List style={{ width: "100%", padding: "0px" }}>
                                                <ListItem
                                                    className={classes.buttonListAdicionarItem}
                                                >
                                                    <Button
                                                        // variant="contained"
                                                        className={classes.buttonAdicionarItem}
                                                        // color="danger"
                                                        onClick={() =>
                                                            handleAdicionarProduto(
                                                                indexC,
                                                                categoria.id
                                                            )
                                                        }
                                                        startIcon={<AddIcon />}
                                                    >
                                                        Adicionar Produto
                                                    </Button>
                                                </ListItem>

                                                {categoria.produtos.map((produto, indexP) => (
                                                    <ListItem
                                                        onClick={() => handleEditProduto(produto)}
                                                        button
                                                        key={indexP}
                                                        className={
                                                            smallSize
                                                                ? classes.smallSize
                                                                : classes.fullSize
                                                        }
                                                    >
                                                        <ListItemAvatar>
                                                            <img
                                                                style={{
                                                                    width: "40px",
                                                                    borderRadius: "100%",
                                                                }}
                                                                src={
                                                                    produto.urlImagem
                                                                        ? produto.urlImagem
                                                                        : Comida
                                                                }
                                                            />
                                                            {/* <Avatar> */}
                                                            {/* </Avatar> */}
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            style={{ margin: "0px" }}
                                                            primary={
                                                                <span className={classes.descricao}>
                                                                    {produto.nome}
                                                                </span>
                                                            }
                                                            secondary={
                                                                <span className={classes.descricao}>
                                                                    {produto.descricao}
                                                                </span>
                                                            }
                                                        />
                                                        <ListItemSecondaryAction
                                                            className={classes.listItem}
                                                        >
                                                            {!smallSize && (
                                                                <>
                                                                    <TextField
                                                                        // label="With normal TextField"
                                                                        // id="outlined-start-adornment"
                                                                        defaultValue={produto.valor}
                                                                        size="small"
                                                                        disabled
                                                                        className={clsx(
                                                                            classes.margin,
                                                                            classes.textField
                                                                        )}
                                                                        // onChange={(e) => {
                                                                        //   changeValuePrice(e)
                                                                        // }}
                                                                        InputProps={{
                                                                            startAdornment: (
                                                                                <InputAdornment position="start">
                                                                                    R$
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                        variant="outlined"
                                                                    />

                                                                    <FormControlLabel
                                                                        style={{
                                                                            margin: "0 0 0 5px",
                                                                        }}
                                                                        onChange={() =>
                                                                            handleAtivamento(
                                                                                indexC,
                                                                                indexP
                                                                            )
                                                                        }
                                                                        control={
                                                                            <Switch
                                                                                checked={
                                                                                    produto.ativo
                                                                                }
                                                                                color="primary"
                                                                                name="checkedA"
                                                                            />
                                                                        }
                                                                    />
                                                                </>
                                                            )}

                                                            {/* <IconButton edge="end" aria-label="editar">
                                        <EditIcon />
                                      </IconButton> */}
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </AccordionDetails>
                                    </Accordion>
                                </Col>
                            ))}
                        </Row>
                        {/* </div> */}
                    </DialogContent>
                    {/* <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancelar
            </Button>
            <Button onClick={handleClose} color="primary">
              Abrir
            </Button>
          </DialogActions> */}
                </Dialog>
            )}

            {produtoEdit && (
                <DialogProduto
                    theme={theme}
                    produto={produtoEdit}
                    setProduto={setProdutoEdit}
                    modoEdicao={modoEdicao}
                />
            )}

            {state.categoriaEdit && (
                <DialogCategoria
                    theme={theme}
                    state={state}
                    categoria={state.categoriaEdit}
                    setState={setState}
                    modoEdicao={modoEdicao}
                />
            )}
        </div>
    )
}
