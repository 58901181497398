import { createContext, useContext, useState } from "react"

export const RecebedorContext = createContext(null)

export function RecebedorContextProvider(props) {
    const [modalPedido, setModalPedido] = useState({})
    const closeModalPedido = () => setModalPedido({})
    const openModalPedido = pedido => setModalPedido(pedido)

    const autorizacao = localStorage.getItem("chaveSistema")

    const value = {
        modalPedido,
        closeModalPedido,
        openModalPedido,
        autorizacao,
    }

    return <RecebedorContext.Provider value={value}>{props.children}</RecebedorContext.Provider>
}

const useRecebedor = () => {
    const context = useContext(RecebedorContext)

    if (!context) throw new Error("useRecebedor must be within a Context Provider")

    return context
}

export default useRecebedor
