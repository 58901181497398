import { useContext } from "react"

import { useSnackbar } from "notistack"
import RegistradorContext from "../../../../contexts/registradorContext"
import ServicosContext from "../../../../contexts/servicosContext"

import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import { Col, Row } from "react-bootstrap"
// import REGISTRADOR_ICON from '../../../../assets/icons/svg/049-circulation.svg';

import { listarClientes, RegistrarLogErro } from "../../../../services/clientefiel"

import { BuscarMesas } from "../../../../services/integracao"

import { carrinhoNovo } from "../../../../pages/RegistradorDePedidos/functions"

export default function Index() {
    const autorizacao = localStorage.getItem("chaveSistema")
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
    )
    const aplicativoDados = JSON.parse(localStorage.getItem("aplicativoCF"))

    const {
        carrinho,
        setCarrinho,
        setOpenListaClientes,
        setLoadingClientes,
        setListaClientes,
        setOpenCadastroCliente,
        setOpenListaEnderecos,
        setOpenGerenciarMesa,
        setListaMesas,
    } = useContext(RegistradorContext)

    const { enqueueSnackbar } = useSnackbar()

    const { configuracoes } = useContext(ServicosContext)

    const alertStart = (msg, tipo) => {
        enqueueSnackbar(msg, { variant: tipo })
    }

    const handleOpenGerenciamentoMesa = async () => {
        try {
            setOpenGerenciarMesa(true)

            const retorno = await BuscarMesas(autorizacao)
            if (retorno.retornoErro) {
                alertStart(retorno.mensagem, "error")
                return false
            }

            if (retorno.length === 0) {
                setListaMesas([{ naoTem: true }])
            } else {
                setListaMesas(retorno)
            }
        } catch (error) {
            RegistrarLogErro(error, "handleOpenGerenciamentoMesa")
            alertStart("Procure os desenvolvedores! Erro: " + error.message, "error")
        }
    }

    const handleBuscarClientes = async e => {
        try {
            console.log("||> handleBuscarClientes", e)
            setCarrinho(carrinhoNovo())
            e.preventDefault()
            const nomePesquisa = document.getElementById("procurar-cliente").value
            setOpenListaClientes(true)
            setLoadingClientes(true)
            const data = {
                idEstabelecimento: configuracoes.user.parametros.estabelecimento.id,
                nomeCliente: nomePesquisa,
                maxResultado: 100,
                token: "a~I?;;+_@!AL^",
            }
            const response = await listarClientes(autorizacao, data)

            if (response.retornoErro) {
                alertStart(response.mensagem, "error")
                return false
            }

            setListaClientes(response)
            setLoadingClientes(false)
        } catch (error) {
            RegistrarLogErro(
                error,
                "handleBuscarClientes",
                document.getElementById("procurar-cliente").value
            )
            alertStart("Procure os desenvolvedores! Erro: " + error.message, "error")
        }
    }

    return (
        <form onSubmit={handleBuscarClientes} style={mobile ? { marginBottom: "10em" } : undefined}>
            <Row style={{ marginTop: "2em" }} className="container-registrador">
                <Col xs={12} md={12} lg={12} style={{ marginBottom: "10px", textAlign: "center" }}>
                    <img
                        src={
                            configuracoes.user?.parametros.estabelecimento?.urlLogo
                                ? configuracoes.user?.parametros.estabelecimento?.urlLogo
                                : aplicativoDados.urlLogo
                        }
                        style={{ height: "150px", marginBottom: "2em", opacity: "0.8" }}
                        alt=""
                    />
                </Col>
                <Col xs={12} md={12} lg={12} style={{ marginBottom: "10px", textAlign: "center" }}>
                    <TextField
                        id="procurar-cliente"
                        autoFocus
                        fullWidth
                        label="Procurar Cliente (Nome ou Telefone)"
                        variant="outlined"
                        style={{ background: "#fff" }}
                    />
                </Col>

                <Col xs={12} md={12} lg={12}>
                    <Button
                        fullWidth
                        className="mt-4"
                        onClick={handleBuscarClientes}
                        variant="contained"
                    >
                        Buscar Cliente
                    </Button>
                </Col>
                <Col xs={12} md={6} lg={4}>
                    <Button
                        fullWidth
                        onClick={() => setOpenCadastroCliente(true)}
                        className="mt-4"
                        variant="contained"
                    >
                        Cadastrar Cliente
                    </Button>
                </Col>

                <Col xs={12} md={6} lg={4}>
                    <Button
                        fullWidth
                        onClick={() => {
                            const cart = carrinhoNovo()
                            setCarrinho(cart)
                            setOpenListaEnderecos(true)
                        }}
                        className="mt-4"
                        variant="contained"
                    >
                        Pedido Anônimo
                    </Button>
                </Col>

                <Col xs={12} md={6} lg={4}>
                    <Button
                        fullWidth
                        onClick={handleOpenGerenciamentoMesa}
                        className="mt-4"
                        variant="contained"
                    >
                        Gerenciar Mesas
                    </Button>
                </Col>
            </Row>
        </form>
    )
}
