import {
    Avatar,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    ListItem,
    Menu,
    MenuItem,
    TextField,
    Typography,
    makeStyles,
} from "@material-ui/core"
import {
    AirlineSeatReclineNormal,
    ArrowForwardIos,
    DirectionsWalk,
    MoreVert,
    Motorcycle,
    NavigateNext,
    Print,
    PrintDisabled,
    Visibility,
} from "@material-ui/icons"
import { useSnackbar } from "notistack"
import { createContext, useContext, useState } from "react"
import { useDrag } from "react-dnd"
import iconClienteFiel from "../../../assets/icon-512x512.png"
import iconIfood from "../../../assets/ifood-logo.png"
import useRecebedor from "../../../contexts/RecebedorContext"
import LoadingContext from "../../../contexts/loadingContext"
import ServicosContext from "../../../contexts/servicosContext"
import RepresentanteSelector from "../../../functions/RepresentanteSelector"
import { cancelarNotaFiscal } from "../../../services/integracao"
import IntegracaoUber from "../IntegracaoUber/IntegracaoUber"
import { UberIcon } from "../IntegracaoUber/assets"
import { verificarPrintQRCode } from "../functions"

const coresAgrupamentos = ["#f00a", "#0f0a", "#00fa", "#ff0a", "#0ffa", "#f0fa"]

const useStyles = makeStyles({
    pedido: props => {
        if (props.isDragging) {
            return {
                border: "2px dashed rgba(0,0,0,0.2)",
                padding: "0",
                borderRadius: "0",
                background: "transparent",
                boxShadow: "none",
                "& > *": {
                    opacity: 0,
                },
            }
        } else
            return {
                "& > *": {
                    opacity: 1,
                },
            }
    },
    maisOpcoes: {
        fontSize: "1.3rem",
    },
    status: {
        borderRadius: "10px",
        color: "white",
        padding: props => (props.indexLista !== 1 ? "0.075rem 0.5rem" : "0"),
        backgroundColor: props => {
            switch (props.indexLista) {
                case 2:
                    return "#fd7e14"
                case 3:
                    return "#0080ff"
                case 4:
                    return props.statusPedido > 0 ? "#51a351" : "#dc3545"
                default:
                    return ""
            }
        },
    },
    atrasado: {
        borderRadius: "10px",
        color: "white",
        padding: "0.075rem 0.5rem",
        backgroundColor: "#dc3545",
    },
    valor: {
        textAlign: "right",
        "& > span": {
            borderRadius: "15px",
            color: "white",
            padding: "0.075rem 0.5rem",
            backgroundColor: "#9a9a9a",
        },
    },
    nomeFormaPagamento: {
        "& > span": {
            display: "inline",
            overflow: "hidden",
            whiteSpace: "nowrap",
        },
    },
    botoesIniciais: {
        display: "flex",
        gap: "0.5rem",
        alignItems: "center",
        width: "90%",
        marginTop: "0.5rem",
    },
    cancelarPedidoButton: {
        backgroundColor: "#dc3545",
        flex: 3,
        color: "white",
        "&:hover": {
            backgroundColor: "#dc3545",
        },
    },
    vizualizarPedidoButton: {
        color: "black",
        flex: 2,
    },
    aceitarPedidoButton: {
        backgroundColor: "#28a745",
        color: "white",
        flex: 3,
        "&:hover": {
            backgroundColor: "#28a745",
        },
    },
    headerActionsContainer: {
        display: "grid",
        gap: "0.25rem",
        gridTemplateColumns: "2rem 2rem 2rem",
        gridTemplateRows: "2rem 2rem",
        float: "right",
        alignSelf: "right",
        "& > div > *": {
            height: "2rem",
            width: "2rem",
            padding: 0,
        },
        "& .actions-dropdown": {
            gridRow: 1,
            gridColumn: 1,
        },
        "& .actions-checkbox": {
            gridRow: 1,
            gridColumn: 2,
        },
        "& .actions-next": {
            gridRow: 1,
            gridColumn: 3,
            "& svg": {
                borderRadius: "100%",
                color: "white",
                backgroundColor: props => {
                    switch (props.indexLista) {
                        case 2:
                            return "#0080ff"
                        case 3:
                            return "#51a351"
                    }
                },
            },
        },
        "& .actions-agrupamento": {
            gridRow: 2,
            gridColumn: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& > div": {
                width: "1.5rem",
                height: "1.5rem",
                fontWeight: 500,
                backgroundColor: props => coresAgrupamentos[props.agrupamentoId - 1] ?? "#bbb",
            },
        },
        "& .actions-print": {
            gridRow: props => (props.statusPedido === 4 || props.statusPedido < 0 ? 1 : 2),
            gridColumn: 2,
        },
        "& .actions-delivery": {
            gridRow: props => (props.statusPedido === 4 || props.statusPedido < 0 ? 1 : 2),
            gridColumn: 3,
            "& .actions-delivery-motoboy": {
                color: props => (props.idEntregador ? "#28a745" : "5f5f5f"),
            },
        },
        "& svg": {
            fontSize: "1.3rem",
        },
    },
})

const PedidoContext = createContext()

const PedidoContextProvider = props => (
    <PedidoContext.Provider value={{ ...props }}>{props.children}</PedidoContext.Provider>
)

const LogoPlataforma = () => {
    const { pedido } = useContext(PedidoContext)

    if (pedido.plataforma === "ifood_integracao")
        return <img src={iconIfood} style={{ width: "25px" }} alt="pedido ifood" />

    if (RepresentanteSelector.getRepresentante().isClienteFiel())
        return <img src={iconClienteFiel} style={{ width: "15px" }} alt="pedido cliente fiel" />

    return <></>
}

const InformacoesPedido = () => {
    const { pedido } = useContext(PedidoContext)

    return (
        <span>
            <Typography variant="body2">
                <LogoPlataforma /> <b>#{pedido.id}</b> - {pedido.createdAt.slice(-5)}
            </Typography>
        </span>
    )
}

const NomeCliente = () => {
    const { pedido } = useContext(PedidoContext)

    return (
        <Typography component="span" color="textPrimary" className="text-capitalize">
            {pedido.customer.name}
            {pedido.type === "2"
                ? pedido.mesa
                    ? " - Mesa " + pedido.mesa
                    : " - Consumir no local"
                : ""}
        </Typography>
    )
}

const PedidoHeaderActions = props => {
    const {
        pedido,
        indexPedido,
        indexLista,
        tabStatus,
        mudarStatus,
        setAbrirCancelarNF,
        configuracoes,
        listaPedidosSelecionados,
        setListaPedidosSelecionados,
        handlePrint,
        autorizacao,
        handleClickEntregadorIcon,
        corIconeUber,
        solicitarEntregaFuiDelivery,
        permiteModuloMotoqueiro,
        iconeUberPendente,
        possuiIntegracaoEntrega,
        adicionarPedidoSelecionado,
        removerPedidoSelecionado,
        setAbrirDialogMotoqueiros,
        agrupamentoId,
    } = useContext(PedidoContext)

    const classes = useStyles({
        indexLista,
        assinaturaLimitada: configuracoes.user.parametros.assinaturaLimitada,
        agrupamentoId,
        statusPedido: pedido.status,
        idEntregador: pedido.idEntregador,
    })

    const [anchorEl, setAnchorEl] = useState(null)
    const abrirDropdown = Boolean(anchorEl)

    const isPainelAtivo = tabStatus === "CRUD"
    const mostrarNextButton = isPainelAtivo && indexLista !== 1 && indexLista !== 4
    const mostrarCheckbox = isPainelAtivo && indexLista !== 1
    const integradoComUber = configuracoes.user?.parametros?.possuiIntegracaoUber

    const isAtivoNotaFiscal = configuracoes.user.parametros.ativarPlugNotas

    const handleCheck = e => {
        // TODO: Subir para PedidoCard
        e.stopPropagation()
        if (listaPedidosSelecionados.includes(pedido.id)) removerPedidoSelecionado(pedido)
        else adicionarPedidoSelecionado(pedido)
    }

    const handleOpenDropdownMenu = e => {
        setAnchorEl(e.currentTarget)
        e.stopPropagation()
    }

    const handleCloseDropdownMenu = e => {
        setAnchorEl(null)
        e.stopPropagation()
    }

    const handleImprimirNota = e => {
        e.stopPropagation()
        props.imprimirNotaFiscal(pedido.id)
    }

    const handleCancelarNota = e => {
        e.stopPropagation()
        setAbrirCancelarNF(true)
    }

    return (
        <div className={classes.headerActionsContainer}>
            {isAtivoNotaFiscal ? (
                <>
                    <IconButton
                        className="actions-dropdown"
                        id="dropdown-button"
                        onClick={handleOpenDropdownMenu}
                    >
                        <MoreVert className={classes.maisOpcoes} />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={abrirDropdown}
                        onClose={handleCloseDropdownMenu}
                    >
                        <MenuItem onClick={handleImprimirNota}>Imprimir Nota Fiscal</MenuItem>
                        <Divider />
                        <MenuItem onClick={handleCancelarNota}>Cancelar Nota Fiscal</MenuItem>
                    </Menu>
                </>
            ) : null}
            {agrupamentoId ? (
                <div className="actions-agrupamento">
                    <Avatar>{agrupamentoId}</Avatar>
                </div>
            ) : null}
            {indexLista !== 4 ? (
                <div className="actions-checkbox" onClick={e => e.stopPropagation()}>
                    <Checkbox
                        onChange={handleCheck}
                        checked={listaPedidosSelecionados.includes(pedido.id)}
                        size="small"
                    />
                </div>
            ) : null}
            {mostrarNextButton ? (
                <IconButton
                    className="actions-next pedido-next-status"
                    onClick={e => {
                        mudarStatus(pedido, indexPedido)
                        e.stopPropagation()
                    }}
                >
                    <NavigateNext className={classes.icone_proximo} />
                </IconButton>
            ) : null}
            <IconButton className="actions-delivery" onClick={handleClickEntregadorIcon}>
                {
                    // pedido.mesa
                    pedido.type === "2" ? (
                        <AirlineSeatReclineNormal />
                    ) : pedido.type === "1" ? (
                        <DirectionsWalk />
                    ) : integradoComUber ? (
                        <UberIcon color={corIconeUber} pendente={iconeUberPendente} />
                    ) : (
                        <Motorcycle
                            className="actions-delivery-motoboy"
                            onClick={e => {
                                if (possuiIntegracaoEntrega()) {
                                    solicitarEntregaFuiDelivery(autorizacao, pedido)
                                } else if (permiteModuloMotoqueiro() && tabStatus === "CRUD") {
                                    setListaPedidosSelecionados([pedido.id])
                                    setAbrirDialogMotoqueiros(true)
                                }
                                e.stopPropagation()
                            }}
                        />
                    )
                }
            </IconButton>
            <IconButton
                onClick={e => {
                    e.stopPropagation()
                    handlePrint()
                }}
                className="actions-print botao-impressao-manual"
            >
                {configuracoes.user.parametros.assinaturaLimitada ? <PrintDisabled /> : <Print />}
            </IconButton>
        </div>
    )
}

function OpcoesIniciais() {
    const {
        pedido,
        tabStatus,
        mudarStatusPara,
        autorizacao,
        indexPedido,
        handleAbrirDetalhes,
        configuracoes,
        handlePrint,
        tipoPainelImpressao,
        efeitoSonoro,
    } = useContext(PedidoContext)

    const [mostrarOpcoes, setMostrarOpcoes] = useState(true)

    const classes = useStyles()

    if (!mostrarOpcoes) return <></>

    const handleAceitarPedido = async e => {
        e.stopPropagation()
        if (pedido.status === 1 || pedido.status === 2) {
            //pedido ja esta aceito
            if (configuracoes.recebedor.aceitarPedidoAutomatico) {
                setMostrarOpcoes(false)
            }
            mudarStatusPara(7, autorizacao, pedido, indexPedido)
            if (tipoPainelImpressao === "navegador") {
                handlePrint({
                    qtd: configuracoes.user.parametros?.viasImpressao,
                })
            }
        } else {
            //aceitar pedido
            await mudarStatusPara(1, autorizacao, pedido, indexPedido)
        }
    }

    const handleCancelarPedido = e => {
        e.stopPropagation()
        mudarStatusPara(-1, autorizacao, pedido, indexPedido)
    }

    return (
        <span className={classes.botoesIniciais}>
            {tabStatus === "CRUD" && (
                <Button
                    onClick={handleCancelarPedido}
                    variant="contained"
                    size="small"
                    className={classes.cancelarPedidoButton}
                >
                    Cancelar
                </Button>
            )}
            <Button
                size="small"
                variant="contained"
                className={classes.vizualizarPedidoButton}
                onClick={handleAbrirDetalhes}
            >
                <Visibility />
            </Button>
            {tabStatus === "CRUD" && (
                <Button
                    onClick={handleAceitarPedido}
                    variant="contained"
                    size="small"
                    className={classes.aceitarPedidoButton}
                >
                    {pedido.status === 1 || pedido.status === 2 ? "Preparar" : "Aceitar"}
                </Button>
            )}
        </span>
    )
}

const ValorPedido = () => {
    const { pedido } = useContext(PedidoContext)

    const valor = Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
    }).format(pedido.totalPrice)

    return (
        <Typography component="span" variant="body2">
            {valor}
        </Typography>
    )
}

const NomeFormaPagamento = () => {
    const { pedido } = useContext(PedidoContext)

    return <span>{pedido.status >= 0 ? pedido.payments.name : ""}</span>
}

const EnderecoEntrega = props => (
    <Typography variant="subtitle2" color="textSecondary">
        {props.endereco.neighborhood} - {props.endereco.streetName}, {props.endereco.streetNumber}
    </Typography>
)

const NomeEntregadorAssociado = props => (
    <Typography
        id={`entregador-pedido-${props.idPedido}`}
        variant="subtitle2"
        color="textSecondary"
    >
        Entregador: {props.nomeEntregador}
    </Typography>
)

export default function PedidoCard(props) {
    const { configuracoes } = useContext(ServicosContext)

    const { setLoading } = useContext(LoadingContext)

    const { enqueueSnackbar } = useSnackbar()
    const { openModalPedido } = useRecebedor()

    const [{ isDragging }, dragRef] = useDrag({
        type: "CARD",
        item: () => ({
            pedido: pedido,
            indexListaAtual: indexLista,
            indexPedido: indexPedido,
        }),
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    })

    const [abrirCancelarNF, setAbrirCancelarNF] = useState(false)

    const cancelarNF = async () => {
        const response = await cancelarNotaFiscal({
            id: pedido.id,
            justificativaCancelamentoNotaFiscal: document.getElementById("justificativa-nf").value,
        })

        alertStart(response.mensagem ?? response.message, response.codErro ? "error" : "info")

        setAbrirCancelarNF(false)
    }

    const alertStart = (msg, tipo) => {
        enqueueSnackbar(msg, { variant: tipo })
    }

    const {
        indexLista,
        pedido,
        indexPedido,
        autorizacao,
        mudarStatusPara,
        mudarStatus,
        mandarImprimir,
        tabStatus,
        procurarMotoqueiros,
        solicitarEntregaFuiDelivery,
        buscarStatus,
        tipoPainelImpressao,
        listaPedidosSelecionados,
        setListaPedidosSelecionados,
        imprimirNotaFiscal,
        efeitoSonoro,
        adicionarPedidoSelecionado,
        removerPedidoSelecionado,
        setAbrirDialogMotoqueiros,
        agrupamentos,
    } = props
    const classes = useStyles({ indexLista, isDragging, statusPedido: pedido.status })

    const [expandIntegracaoUber, setExpandIntegracaoUber] = useState(false)
    const toggleExpandIntegracaoUber = e => setExpandIntegracaoUber(prev => !prev)

    const [corIconeUber, setCorIconeUber] = useState()
    const [iconeUberPendente, setIconeUberPendente] = useState(false)

    const integradoComUber = configuracoes.user?.parametros?.possuiIntegracaoUber

    const permiteModuloMotoqueiro = () => {
        switch (indexLista) {
            case 4:
                return false
            default:
                return true
        }
    }

    const possuiIntegracaoEntrega = () => {
        return (
            configuracoes.user.parametros.ativarFuiDelivery &&
            configuracoes.user.parametros.passwordFuiDelivery &&
            configuracoes.user.parametros.usernameFuiDelivery &&
            configuracoes.user.parametros.clienteIdFuiDelivery
        )
    }

    const handleAbrirDetalhes = () => openModalPedido(pedido)

    const handleClickEntregadorIcon = e => {
        e.stopPropagation()

        if (integradoComUber && pedido.type === "0") {
            toggleExpandIntegracaoUber()
            return
        }

        if (pedido.type !== "2" && pedido.type !== "1") {
            if (possuiIntegracaoEntrega()) {
                solicitarEntregaFuiDelivery(autorizacao, pedido)
            } else if (permiteModuloMotoqueiro() && tabStatus === "CRUD") {
                adicionarPedidoSelecionado(pedido)
                setAbrirDialogMotoqueiros(true)
            }
        }
    }

    const handlePrint = customParams => {
        mandarImprimir({
            pedido: pedido,
            texto: pedido.texto,
            qtd: customParams?.qtd ?? 1,
            qrcode: verificarPrintQRCode(pedido),
            imprimirNota: true,
        })
    }

    // console.log("PEDIDO", pedido.id, pedido)

    if (indexPedido > configuracoes.recebedor.exibicaoMaxima) return <></>
    //TODO: Tirar span

    const agrupamentoId = agrupamentos.findIndex(a => a === pedido.agrupamento) + 1

    return (
        <span key={pedido.id}>
            <PedidoContextProvider
                pedido={pedido}
                indexPedido={indexPedido}
                indexLista={indexLista}
                classes={classes}
                tabStatus={tabStatus}
                mudarStatus={mudarStatus}
                mudarStatusPara={mudarStatusPara}
                configuracoes={configuracoes}
                listaPedidosSelecionados={listaPedidosSelecionados}
                setListaPedidosSelecionados={setListaPedidosSelecionados}
                handlePrint={handlePrint}
                tipoPainelImpressao={tipoPainelImpressao}
                autorizacao={autorizacao}
                handleClickEntregadorIcon={handleClickEntregadorIcon}
                procurarMotoqueiros={procurarMotoqueiros}
                corIconeUber={corIconeUber}
                setCorIconeUber={setCorIconeUber}
                possuiIntegracaoEntrega={possuiIntegracaoEntrega}
                solicitarEntregaFuiDelivery={solicitarEntregaFuiDelivery}
                permiteModuloMotoqueiro={permiteModuloMotoqueiro}
                iconeUberPendente={iconeUberPendente}
                setIconeUberPendente={setIconeUberPendente}
                handleAbrirDetalhes={handleAbrirDetalhes}
                abrirCancelarNF={abrirCancelarNF}
                setAbrirCancelarNF={setAbrirCancelarNF}
                efeitoSonoro={efeitoSonoro}
                adicionarPedidoSelecionado={adicionarPedidoSelecionado}
                removerPedidoSelecionado={removerPedidoSelecionado}
                setAbrirDialogMotoqueiros={setAbrirDialogMotoqueiros}
                agrupamentoId={agrupamentoId}
            >
                <ListItem
                    id={`pedido-${pedido.id}`}
                    key={pedido.id}
                    disableGutters
                    button
                    onClick={handleAbrirDetalhes}
                    ref={
                        pedido.status >= 1 && indexLista != 1 && tabStatus === "CRUD"
                            ? dragRef
                            : null
                    }
                    className={classes.pedido}
                    divider
                >
                    <Grid container>
                        <Grid container item xs={12} justifyContent="space-between">
                            <Grid container item justifyContent="space-between" xs={12}>
                                <Grid item xs={6}>
                                    <InformacoesPedido />
                                    <NomeCliente />
                                </Grid>
                                {pedido.status !== 0 ? (
                                    <PedidoHeaderActions imprimirNotaFiscal={imprimirNotaFiscal} />
                                ) : null}
                            </Grid>
                            <Grid container item xs={12}>
                                <Grid
                                    container
                                    item
                                    xs={6}
                                    direction="row"
                                    wrap="nowrap"
                                    spacing={1}
                                >
                                    {pedido.status !== 0 && (
                                        <Grid item>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                color="textPrimary"
                                                noWrap
                                            >
                                                <span className={classes.status}>
                                                    {indexLista !== 1 ? buscarStatus(pedido) : ""}
                                                </span>
                                            </Typography>
                                        </Grid>
                                    )}
                                    <Grid item className={classes.nomeFormaPagamento}>
                                        {/* {atraso ? (
                                            <span className={classes.atrasado}>
                                                ATRASADO
                                            </span>
                                        ) : ( */}
                                        <NomeFormaPagamento />
                                        {/* )} */}
                                    </Grid>
                                </Grid>
                                <Grid item xs={6} className={classes.valor}>
                                    <ValorPedido />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            {configuracoes.recebedor.mostrarEndereco &&
                                pedido.deliveryAddress.streetName !== "Buscar no local" && (
                                    <EnderecoEntrega endereco={pedido.deliveryAddress} />
                                )}
                            {configuracoes.recebedor.mostrarEntregador && pedido.nomeEntregador && (
                                <NomeEntregadorAssociado
                                    nomeEntregador={pedido.nomeEntregador}
                                    idPedido={pedido.id}
                                />
                            )}
                        </Grid>
                        <Grid container item xs={12} justifyContent="center">
                            {indexLista !== 1 && pedido.type === "0" && integradoComUber && (
                                <IntegracaoUber
                                    expandIntegracaoUber={expandIntegracaoUber}
                                    setExpandIntegracaoUber={setExpandIntegracaoUber}
                                    pedido={pedido}
                                    indexPedido={indexPedido}
                                    autorizacao={autorizacao}
                                    mudarStatus={mudarStatus}
                                    setCorIconeUber={setCorIconeUber}
                                    setIconeUberPendente={setIconeUberPendente}
                                    alertStart={alertStart}
                                />
                            )}
                            {indexLista === 1 && <OpcoesIniciais />}
                        </Grid>
                    </Grid>
                </ListItem>
                <Dialog
                    maxWidth={"xs"}
                    fullWidth
                    open={abrirCancelarNF}
                    onClose={() => setAbrirCancelarNF(false)}
                >
                    <DialogTitle>
                        <IconButton
                            style={{
                                color: "black",
                                transform: "rotate(180deg)",
                                margin: "-12px",
                                marginRight: "6px",
                                padding: "8px",
                            }}
                            onClick={() => setAbrirCancelarNF(false)}
                        >
                            <ArrowForwardIos />
                        </IconButton>
                        Cancelar Nota Fiscal
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            fullWidth
                            multiline
                            minRows={3}
                            label="Motivo"
                            variant="outlined"
                            id="justificativa-nf"
                        />
                    </DialogContent>
                    <DialogActions style={{ justifyContent: "space-between" }}>
                        {/* // TODO: Precisa desse botão FECHAR? */}
                        <Button variant="contained" onClick={() => setAbrirCancelarNF(false)}>
                            Fechar
                        </Button>
                        <Button
                            variant="contained"
                            style={{ backgroundColor: "#dc3545" }}
                            onClick={cancelarNF}
                        >
                            Cancelar
                        </Button>
                    </DialogActions>
                </Dialog>
            </PedidoContextProvider>
        </span>
    )
}
