import { RiArrowLeftLine } from "react-icons/ri"
import { useHistory } from "react-router-dom"
/*import { Button, Navbar, Nav, NavDropdown } from 'react-bootstrap';


import './styles.css';*/
import AppBar from "@material-ui/core/AppBar"
import Button from "@material-ui/core/Button"
import { fade, makeStyles } from "@material-ui/core/styles"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"

import { Badge, Tooltip } from "@material-ui/core"
import IconButton from "@material-ui/core/IconButton"
import { Notifications } from "@material-ui/icons"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"
import { Alert } from "@material-ui/lab"

const useStyles = makeStyles(theme => ({
    root: {
        position: "fixed",
        flexGrow: "1",
        top: "0",
        width: "100%",
        zIndex: "999",
        cursor: "pointer",
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        textTransform: "capitalize",
        flexGrow: 1,
        display: "block",
        [theme.breakpoints.up("sm")]: {
            display: "block",
        },
    },
    titleProduto: {
        textTransform: "uppercase",
        position: "absolute",
        right: 0,
        flexGrow: 0,
        display: "block",
        [theme.breakpoints.up("sm")]: {
            display: "block",
        },
    },
    search: {
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        "&:hover": {
            width: "auto",
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: "150px",
        [theme.breakpoints.up("lg")]: {
            marginLeft: theme.spacing(1),
            width: "auto",
        },
    },
    iconeImg: {
        // padding: theme.spacing(0, 2),
        height: "100%",
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        left: "0",
    },
    voltarIcon: {
        padding: theme.spacing(0, 2),
        height: "100%",
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        left: "0",
        fontSize: "1.5rem",
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    inputRoot: {
        color: "inherit",
    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: "flex-start",
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            width: "12ch",
            "&:focus": {
                width: "20ch",
            },
        },
    },
    botaoQRCode: {
        display: "flex",
        gap: "0.75rem",
        "& > button": {
            backgroundColor: "#6c757d",
            color: "white",
        },
        "& .no-mobile": {
            marginRight: "0.25rem",
            [theme.breakpoints.down("xs")]: {
                display: "none",
            },
        },
    },
    botaoAviso: {
        color: "yellow",
        margin: "0 1rem",
        "& > span > span": {
            "& > span": {
                backgroundColor: "white",
                color: "black",
            },
        },
    },
}))

export default function Cabecalho(props) {
    const history = useHistory()
    const {
        cor,
        icone,
        pagina,
        subtitulo,
        voltar = true,
        desconect = true,
        desconectar,
        registrarPedidoMesa,
        setAbrirCaixa,
        trocarEstabelecimento,
        estabelecimentos,
        handleClose = history.goBack,
        gerarQRCode,
        avisoDoisRecebedores,
        botaoModoVisualizacao,
        setTabStatus = () => {},
        alertas,
        openAlertas,
    } = props

    const classes = useStyles()
    return (
        <div className={classes.root} id="cabecalhoApp">
            <AppBar
                position="static"
                style={cor ? { backgroundColor: `${cor}` } : undefined}
                className="cabecalhoApp"
            >
                <Toolbar className="container">
                    {voltar && (
                        <div
                            className={classes.voltarIcon}
                            color="inherit"
                            // onClick={() => history.push("/servicos")}
                            onClick={handleClose}
                            aria-label="open drawer"
                        >
                            <RiArrowLeftLine></RiArrowLeftLine>
                        </div>
                    )}

                    {icone && (
                        <div className={classes.iconeImg} color="inherit" aria-label="open drawer">
                            <img
                                src={icone}
                                style={{ height: "50px", borderRadius: "100%", marginRight: "2em" }}
                                alt=""
                            />
                        </div>
                    )}

                    <Typography
                        className={classes.title}
                        variant="h6"
                        noWrap
                        style={{ marginLeft: "2em" }}
                    >
                        {pagina}{" "}
                        {subtitulo && (
                            <span style={{ fontSize: "50%", color: "grey" }}>{subtitulo}</span>
                        )}
                    </Typography>

                    {desconectar && (
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="end"
                            onClick={desconectar}
                        >
                            <ExitToAppIcon />
                        </IconButton>
                    )}

                    {registrarPedidoMesa && (
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="end"
                            onClick={registrarPedidoMesa}
                        >
                            Registrar
                        </IconButton>
                    )}

                    {trocarEstabelecimento && (
                        <Button
                            color="inherit"
                            aria-label="open drawer"
                            edge="end"
                            onClick={() => {
                                // trocarEstabelecimento()
                            }}
                        >
                            {estabelecimentos.selecionado.nome}
                        </Button>
                    )}

                    {gerarQRCode && (
                        <div className={classes.botaoQRCode}>
                            <Button
                                onClick={() => history.push("/servicos/ferramenta/pedido-de-mesa")}
                                variant="contained"
                            >
                                <span className="no-mobile">Gerar</span> QRCodes
                            </Button>
                        </div>
                    )}

                    {avisoDoisRecebedores && (
                        <div id="aviso-dois-recebedores" style={{ display: "none" }}>
                            <Tooltip
                                arrow
                                title="Mais de um recebedor aberto pode impactar no recebimento automático dos seus pedidos."
                            >
                                <Alert severity="info" elevation={3}>
                                    Há outro recebedor aberto
                                </Alert>
                            </Tooltip>
                        </div>
                    )}

                    {alertas?.length ? (
                        <IconButton className={classes.botaoAviso} onClick={openAlertas}>
                            <Badge
                                badgeContent={alertas.length}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                }}
                            >
                                <Notifications fontSize="large" />
                            </Badge>
                        </IconButton>
                    ) : null}

                    {botaoModoVisualizacao ? (
                        <div className={classes.botaoQRCode}>
                            <Button
                                variant="contained"
                                color="default"
                                onClick={() => setTabStatus("VIEW")}
                            >
                                Ativar Modo Visualização
                            </Button>
                        </div>
                    ) : null}

                    {/* 
            {setAbrirCaixa && <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={setAbrirCaixa}
            >
              Abrir Caixa
            </IconButton>} */}
                </Toolbar>
            </AppBar>
        </div>
    )
}
