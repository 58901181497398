import { RegistrarLogErro } from "../../services/clientefiel"

function chr(n) {
    return String.fromCharCode(n)
}
var code = "12345"

function tamanhoFont(texto) {
    return "\x0A" + "\x1B" + texto + "\x0D" + "\x1BSe\x0D"
}

function doubleFont(texto) {
    return "\x1B" + "\x4D" + "\x30" + "\x1D" + "\x21" + "\x11" + texto
}

function standardFont(texto) {
    return "\x1B" + "\x4D" + "\x30" + "\x1D" + "\x21" + "\x00" + texto
}

function smallFont(texto) {
    return "\x1D" + "\x21" + "\x00" + "\x1B" + "\x4D" + "\x30" + "\x1B" + "\x4D" + "\x31" + texto
}

function encondingPortugues() {
    return "\x1B" + "\x74" + "\x10"
}

function cortarComanda() {
    return "\x0A\x0A\x0A\x0A\x0A\x0A\x0A\x1B\x69"
}

export function textoConfigurado(configuracoes) {
    let { impressora, qz, printer, texto, qtd, qrcode, logo } = configuracoes

    console.log("printer.tamanhoFonteSelect", printer.tamanhoFonteSelect)
    texto =
        encondingPortugues() +
        `${
            printer.tamanhoFonteSelect === "pequena"
                ? smallFont(texto)
                : printer.tamanhoFonteSelect === "grande"
                ? doubleFont(texto)
                : standardFont(texto)
        }` +
        cortarComanda()

    const removerBarraN = 2
    for (let index = 0; index < removerBarraN; index++) {
        texto = texto.replace("\n", "")
    }

    return texto
}

export function imprimirCode(configuracoes) {
    let {
        pedido = {},
        texto = "",
        qtd = 1,
        qrcode = {},
        QZObrigatorio = false,
        numeroDaImpressora = 0,
        impressora = "",
        logo,
        printer,
        qz,
    } = configuracoes

    // qrcode = {descricao: 'PIX', texto: 'PIX'}

    var config = qz.configs.create(impressora, configImage(qtd)) // Toggle Cp1252 in Java
    console.log("config impressao", config, qtd)
    let imprimir = ""

    var qrCodePrint = ""

    if (logo) {
        // imprimir logo estabelecimento
        qrCodePrint = logo
    }

    if (qrcode.texto) {
        var opts = {
            errorCorrectionLevel: "H",
            type: "image/jpeg",
            scale: 10,
        }
        var QRCode = require("qrcode")
        QRCode.toDataURL(qrcode.texto, opts, function (err, url) {
            qrCodePrint = url
        })
    }

    console.log("mandei imprimir", config, { imprimir })
    imprimir = textoConfigurado(configuracoes)
    qz.print(config, [
        {
            data: "\x1B" + "\x61" + "\x31",
        },
        dataImage(qrCodePrint),
        {
            data: "\x1B" + "\x61" + "\x31" + (qrcode.descricao ? qrcode.descricao : "") + "\n",
        },
        {
            data: "\x1B" + "\x61" + "\x30" + imprimir,
        },
    ])
}

function imprimirComQRCode(configuracoes) {
    const { qtd = 1, qrcode = {}, impressora = "", qz, printer } = configuracoes

    const QRCode = require("qrcode")

    var config = qz.configs.create(impressora, { encoding: "ISO-8859-1" })

    const tamanhoFonte =
        printer.tamanhoFonteSelect === "pequena"
            ? "\x1B" + "\x4D" + "\x31"
            : printer.tamanhoFonteSelect === "grande"
            ? "\x1D" + "\x21" + "\x11"
            : ""

    const QRCodeOpts = { scale: 7 } // TODO: Checar se usa tamanhoFonteSelect para alterar

    QRCode.toDataURL(qrcode.link, QRCodeOpts, (error, qrCodeURL) => {
        if (error) {
            console.error(error)
            return
        }

        for (var i = 0; i < qtd; i++) {
            const printData = [
                "\x1B" + "\x40",
                tamanhoFonte,
                ...qrcode.textosAntes,

                {
                    type: "raw",
                    format: "image",
                    data: qrCodeURL,
                    options: { language: "ESCPOS", dotDensity: "double" },
                },

                ...qrcode.textosDepois,
            ]

            qz.print(config, printData).catch(e => console.error(e))
        }
    })
}

export function imprimirNotaCancelada(configuracoes) {
    let {
        pedido = {},
        texto = "",
        qtd = 1,
        qrcode = {},
        QZObrigatorio = false,
        numeroDaImpressora = 0,
        impressora = "",
        logo,
        printer,
        qz,
    } = configuracoes

    // qrcode = {descricao: 'PIX', texto: 'PIX'}

    var config = qz.configs.create(impressora, configImage()) // Toggle Cp1252 in Java

    for (var i = 0; i < qtd; i++) {
        qz.print(config, [
            {
                data: "\x1B" + "\x61" + "\x31",
            },
            {
                data: "\x1B" + "\x61" + "\x30" + texto,
            },
        ])
    }
}

export function imprimirNotaGeral(configuracoes) {
    try {
        const { qtd = 1, qrcode = {}, impressora = "", qz, printer } = configuracoes

        if (qrcode?.link) {
            imprimirComQRCode(configuracoes)
        } else {
            imprimirNotaCancelada(configuracoes)
        }
    } catch (error) {
        RegistrarLogErro(error, "imprimirNotaGeral", configuracoes)
    }
}

export function imprimirHTML(configuracoes) {
    let {
        pedido = {},
        texto = "",
        qtd = 1,
        qrcode = {},
        QZObrigatorio = false,
        numeroDaImpressora = 0,
        impressora = "",
        logo,
        printer,
        qz,
    } = configuracoes

    let config = qz.configs.create(impressora, configHtml(qtd))
    const tamanhoFonte =
        !printer.tamanhoFonteImpressao || printer.tamanhoFonteImpressao < 6
            ? 14
            : printer.tamanhoFonteImpressao

    let data = [
        {
            type: "html",
            format: "plain", // or 'plain' if the data is raw HTML
            data: `<span 
              style="position:absolute;
                    color:#0c0d0e;
                    top:0;
                    width:100%;
                    margin:0;
                    padding:0;
                    font-weight: 700;
                    line-height: normal;
                    font-size: ${tamanhoFonte}px;
                    font-family: Arial,sans-serif;
                ">
                ${texto.replace(/\n/g, "<br>")}
              </span>`,
        },
    ]
    console.log("print", config, data)
    qz.print(config, data)
}

export function configHtml(qtd = 1) {
    return {
        copies: qtd,
        scaleContent: true,
        margins: { top: 0, right: 0, bottom: 0, left: 0 },
        orientation: "portrait",
    }
}

export function configCode() {
    return {
        copies: 1,
        scaleContent: true,
        margins: { top: 0, right: 0, bottom: 0, left: 0 },
        orientation: "portrait",
    }
}

export function configImage(qtd = 1) {
    return {
        bounds: null,
        colorType: "grayscale",
        copies: qtd,
        density: 0,
        duplex: false,
        fallbackDensity: null,
        interpolation: "bicubic",
        jobName: null,
        legacy: false,
        margins: 0,
        orientation: null,
        paperThickness: null,
        printerTray: null,
        rasterize: false,
        rotation: 0,
        scaleContent: true,
        size: null,
        units: "in",
        altPrinting: false,
        encoding: "Cp1252",
        spool: {},
    }
}

export function dataImage(imgPrint) {
    if (!imgPrint) {
        return {
            data: "",
        }
    }
    return {
        type: "raw",
        format: "image",
        data: imgPrint,
        options: {
            language: "ESCPOS",
            x: "0",
            y: "0",
            dotDensity: "double",
            xmlTag: "v7:Image",
            pageWidth: "480",
            pageHeight: "",
        },
    }
}
