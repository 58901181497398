import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import React from "react"

import { makeStyles } from "@material-ui/core/styles"

import useMediaQuery from "@material-ui/core/useMediaQuery"

import FormControlLabel from "@material-ui/core/FormControlLabel"

import AppBar from "@material-ui/core/AppBar"
import Switch from "@material-ui/core/Switch"
import Tab from "@material-ui/core/Tab"
import Tabs from "@material-ui/core/Tabs"
import ConfiguracoesIniciais from "./ConfiguracoesIniciais"
import Disponibilidade from "./Disponibilidade"
import GruposDeAdicionais from "./GruposDeAdicionais"

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
        width: "100%",
    },
    rootProduto: {
        // maxWidth: 345,
    },
    categoria: {
        margin: "0px!important",
        alignItems: "center",
    },
    ativo: {},
    inativo: {
        color: "#dc3545",
    },
    descricao: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        // width: "100%!important",
        display: "block",
        width: "95%",
    },
    margin: {
        // margin: theme.spacing(1),
        // padding: '10px',
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    },
    textField: {
        background: "#f9f9f9",
        // width: '7em',
    },
    padding: {
        padding: "10px",
    },
    listItem: {
        display: "flex",
        alignItems: "center",
    },
    fullSize: {
        padding: "4px 200px 4px 16px",
    },
    smallSize: {
        padding: "4px 4px 4px 16px",
    },
    colOptions: {
        display: "flex",
        margin: "0.5em 0px",
        justifyContent: "center",
        alignItems: "center",
    },
    buttonAdicionarCategoria: {},
    buttonListAdicionarItem: {
        justifyContent: "center",
    },
    buttonAdicionarItem: {
        color: "#dc3545",
    },
}))

function getMoney(str) {
    console.log("str", str)
    var value = str.replace(/[\D]+/g, "")
    if (value == "") {
        return value
    }
    return parseInt(value)
}
function formatReal(int) {
    if (int == "") {
        return "0"
    }

    console.log("int", int)
    var tmp = int + ""
    tmp = tmp.replace(/([0-9]{2})$/g, ",$1")
    if (tmp.length > 6) tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2")

    return tmp
}

export default function DialogProduto(props) {
    const classes = useStyles()

    const { theme, produto, setProduto, modoEdicao } = props

    const smallSize = useMediaQuery(theme.breakpoints.down("sm"))
    const [value, setValue] = React.useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const handleClose = () => {
        setProduto(null)
    }

    const changeValuePrice = e => {
        var value = formatReal(getMoney(e.target.value))
        e.target.value = value
    }

    return (
        <div>
            {!!produto && (
                <Dialog
                    open={!!produto}
                    fullScreen={true}
                    onClose={handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">
                        {produto.nome}
                        <FormControlLabel
                            style={{
                                margin: "0 0 0 5px",
                                float: "right",
                            }}
                            control={
                                <Switch checked={produto.ativo} color="primary" name="checkedA" />
                            }
                        />
                    </DialogTitle>

                    <AppBar position="static" color="default">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                            centered
                        >
                            <Tab label="Configurações Iniciais" value={0} />
                            {modoEdicao == "avancado" && (
                                <Tab label="Disponibilidade e Valores" value={1} />
                            )}
                            <Tab label="Grupos de Adicionais" value={2} />
                        </Tabs>
                    </AppBar>

                    <DialogContent>
                        {value == 0 && (
                            <ConfiguracoesIniciais
                                produto={produto}
                                setProduto={setProduto}
                                modoEdicao={modoEdicao}
                            />
                        )}
                        {value == 1 && (
                            <Disponibilidade
                                produto={produto}
                                setProduto={setProduto}
                                modoEdicao={modoEdicao}
                            />
                        )}
                        {value == 2 && (
                            <GruposDeAdicionais
                                produto={produto}
                                setProduto={setProduto}
                                modoEdicao={modoEdicao}
                            />
                        )}
                    </DialogContent>
                    {/* <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancelar
            </Button>
            <Button onClick={handleClose} color="primary">
              Salvar
            </Button>
          </DialogActions> */}
                </Dialog>
            )}
        </div>
    )
}
