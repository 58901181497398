import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardActions from "@material-ui/core/CardActions"
import CardMedia from "@material-ui/core/CardMedia"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import { Col, Row } from "react-bootstrap"

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        margin: "1em 0",
    },
}))

export default function ImagemTematica(props) {
    const classes = useStyles()

    const {
        acessarImagensTematicas,
        aplicativoDados,
        title,
        descricao,
        button,
        background,
        text,
        imagem,
    } = props

    return (
        <Col xs={12} md={6} lg={4} className="colCardServico">
            <Card className={classes.root}>
                {/* <HelpOutlineIcon className="helpCard" onClick={ () => {handleHelp("RegistradorDePedidos") }}/> */}
                <CardActionArea
                    onClick={acessarImagensTematicas}
                    style={{ height: "100%", flexFlow: "column-reverse", alignItems: "normal" }}
                >
                    <Row
                        style={{
                            margin: 0,
                            textAlign: "",
                            width: "100%",
                            padding: "1em",
                            alignItems: "center",
                            background: `${background}`,
                            color: "white",
                        }}
                    >
                        <Col xs={3} md={3} lg={3} style={{ padding: 0 }}>
                            <CardMedia
                                component="img"
                                className={"logoLojas"}
                                style={{ opacity: "0.65" }}
                                alt={aplicativoDados.appNome}
                                image={imagem}
                                title={aplicativoDados.appNome}
                            />
                        </Col>

                        <Col xs={9} md={9} lg={9}>
                            <Typography variant="body2" component="p" style={{ color: `${text}` }}>
                                Imagem Temática
                            </Typography>

                            <Typography gutterBottom component="h2" style={{ color: `${text}` }}>
                                {title}
                            </Typography>

                            <Typography
                                className={"StatusEstabelecimento"}
                                variant="body2"
                                component="p"
                                style={{ color: `${text}` }}
                            >
                                {descricao}
                            </Typography>
                        </Col>
                    </Row>

                    <CardActions style={{ padding: "0" }}>
                        <Typography
                            variant={"button"}
                            style={{
                                textAlign: "center",
                                padding: "3px",
                                backgroundColor: `${button}`,
                                color: `${text}`,
                                width: "100%",
                            }}
                            className={"naoArredondado"}
                        >
                            Acessar
                        </Typography>
                    </CardActions>
                </CardActionArea>
            </Card>
        </Col>
    )
}
