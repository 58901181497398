import Box from "@material-ui/core/Box"
import PropTypes from "prop-types"
import React from "react"

import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"

import { makeStyles } from "@material-ui/core/styles"

import Select from "@material-ui/core/Select"
import Typography from "@material-ui/core/Typography"

import FormControl from "@material-ui/core/FormControl"
import InputAdornment from "@material-ui/core/InputAdornment"
import InputLabel from "@material-ui/core/InputLabel"
import CloseIcon from "@material-ui/icons/Close"
import clsx from "clsx"

import Divider from "@material-ui/core/Divider"

import { Col, Row } from "react-bootstrap"

import Tab from "@material-ui/core/Tab"
import Tabs from "@material-ui/core/Tabs"
import AddIcon from "@material-ui/icons/Add"
function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && <Box style={{ padding: "12px 24px" }}>{children}</Box>}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
    }
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        display: "flex",
        marginTop: "2em",
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },

    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
        width: "100%",
    },
    rootProduto: {
        // maxWidth: 345,
    },
    categoria: {
        margin: "0px!important",
        alignItems: "center",
    },
    ativo: {},
    inativo: {
        color: "#dc3545",
    },
    descricao: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        // width: "100%!important",
        display: "block",
        width: "95%",
    },
    margin: {
        // margin: theme.spacing(1),
        // padding: '10px',
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    },
    textField: {
        background: "#f9f9f9",
        // width: '7em',
    },
    padding: {
        padding: "10px",
    },
    listItem: {
        display: "flex",
        alignItems: "center",
    },
    fullSize: {
        padding: "4px 200px 4px 16px",
    },
    smallSize: {
        padding: "4px 4px 4px 16px",
    },
    colOptions: {
        display: "flex",
        margin: "0.5em 0px",
        justifyContent: "center",
        alignItems: "center",
    },
    colOptionsStart: {
        display: "flex",
        margin: "0.5em 0px",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    colOptionsEnd: {
        display: "flex",
        margin: "0.5em 0px",
        justifyContent: "flex-end",
        alignItems: "center",
    },
    buttonAdicionarCategoria: {},
    buttonListAdicionarItem: {
        justifyContent: "center",
    },
    buttonAdicionarItem: {
        color: "#dc3545",
    },
}))

function getMoney(str) {
    console.log("str", str)
    var value = str.replace(/[\D]+/g, "")
    if (value == "") {
        return value
    }
    return parseInt(value)
}
function formatReal(int) {
    if (int == "") {
        return "0"
    }

    console.log("int", int)
    var tmp = int + ""
    tmp = tmp.replace(/([0-9]{2})$/g, ",$1")
    if (tmp.length > 6) tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2")

    return tmp
}

const changeValuePrice = e => {
    var value = formatReal(getMoney(e.target.value))
    e.target.value = value
}

export default function VerticalTabs(props) {
    const classes = useStyles()
    const [value, setValue] = React.useState(1)
    const aplicativoDados = JSON.parse(localStorage.getItem("aplicativoCF"))

    const { setProduto, produto, modoEdicao } = props

    const handleChange = (event, newValue) => {
        if (newValue == 0) {
            //handleAdicionarGrupoAdicionais
            return true
        }
        setValue(newValue)
    }

    return (
        <>
            <div className={classes.root}>
                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    className={classes.tabs}
                >
                    <Tab
                        {...a11yProps(-1)}
                        label={"Adicionar Grupo"}
                        style={{ color: aplicativoDados.corSitePrimaria, opacity: "1" }}
                    />

                    {produto.opcoes.map((opcao, index) => (
                        <Tab label={opcao.nome} {...a11yProps(index)} />
                    ))}
                </Tabs>

                {produto.opcoes.map((opcao, index) => (
                    <TabPanel value={value} index={index + 1} style={{ width: "100%" }}>
                        <Row>
                            <Col xs={6} md={6} lg={6} className={classes.colOptionsStart}>
                                <Typography variant={"h6"}>Configuração do Grupo</Typography>
                            </Col>
                            <Col xs={6} md={6} lg={6} className={classes.colOptionsEnd}>
                                <Button
                                    className={classes.buttonAdicionarItem}
                                    // onClick={() => handleAdicionarProduto(indexC)}
                                    startIcon={<CloseIcon />}
                                >
                                    Excluir Grupo
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={1} md={1} lg={1} className={classes.colOptions}>
                                <TextField
                                    defaultValue={opcao.ordemExib}
                                    label="Posição"
                                    fullWidth
                                    className={clsx(classes.margin, classes.textField)}
                                    onChange={e => {}}
                                    variant="outlined"
                                />
                            </Col>
                            <Col xs={7} md={7} lg={7} className={classes.colOptions}>
                                <TextField
                                    defaultValue={opcao.nome}
                                    label="Nome do Grupo"
                                    fullWidth
                                    className={clsx(classes.margin, classes.textField)}
                                    onChange={e => {}}
                                    variant="outlined"
                                />
                            </Col>
                            <Col xs={2} md={2} lg={2} className={classes.colOptions}>
                                <TextField
                                    defaultValue={opcao.quantidadeMinima}
                                    label="Quantid. Mínima"
                                    fullWidth
                                    className={clsx(classes.margin, classes.textField)}
                                    onChange={e => {}}
                                    variant="outlined"
                                />
                            </Col>
                            <Col xs={2} md={2} lg={2} className={classes.colOptions}>
                                <TextField
                                    defaultValue={opcao.quantidadeMinima}
                                    label="Quantid. Mínima"
                                    fullWidth
                                    className={clsx(classes.margin, classes.textField)}
                                    onChange={e => {}}
                                    variant="outlined"
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={4} md={4} lg={3} className={classes.colOptions}>
                                <FormControl
                                    fullWidth
                                    variant="outlined"
                                    className={classes.formControl}
                                >
                                    <InputLabel htmlFor="tipoAdicional">
                                        Tipo de Adicionais
                                    </InputLabel>
                                    <Select
                                        native
                                        fullWidth
                                        defaultValue={1}
                                        label="Tipo de Adicionais"
                                        className={clsx(classes.margin, classes.textField)}
                                        inputProps={{
                                            name: "Tipo de Adicionais",
                                            id: "tipoAdicional",
                                        }}
                                    >
                                        <option value={0}>Obrigatório</option>
                                        <option value={1}>Optativo</option>
                                        <option value={2}>Quantitativo</option>
                                        <option value={3}>Digitável</option>
                                    </Select>
                                </FormControl>
                            </Col>
                            <Col xs={4} md={4} lg={3} className={classes.colOptions}>
                                <FormControl
                                    fullWidth
                                    variant="outlined"
                                    className={classes.formControl}
                                >
                                    <InputLabel htmlFor="consideracoes">Considerações</InputLabel>
                                    <Select
                                        native
                                        fullWidth
                                        defaultValue={0}
                                        label="Considerações"
                                        className={clsx(classes.margin, classes.textField)}
                                        inputProps={{
                                            name: "Considerações",
                                            id: "consideracoes",
                                        }}
                                    >
                                        <option value={0}>Soma dos valores</option>
                                        <option value={1}>Maior dos valores</option>
                                        <option value={2}>Media dos valores</option>
                                    </Select>
                                </FormControl>
                            </Col>
                            {modoEdicao == "avancado" && (
                                <Col xs={4} md={4} lg={3} className={classes.colOptions}>
                                    <FormControl
                                        fullWidth
                                        variant="outlined"
                                        className={classes.formControl}
                                    >
                                        <InputLabel htmlFor="diaDaSemana">Dia da Semana</InputLabel>
                                        <Select
                                            native
                                            fullWidth
                                            label="Dia da Semana"
                                            className={clsx(classes.margin, classes.textField)}
                                            inputProps={{
                                                name: "Dia da Semana",
                                                id: "diaDaSemana",
                                            }}
                                        >
                                            <option value={0}>Todos os dias</option>
                                            <option value={0}>Domingo</option>
                                            <option value={1}>Segunda</option>
                                            <option value={2}>Terça</option>
                                            <option value={2}>Quarta</option>
                                            <option value={2}>Quinta</option>
                                            <option value={2}>Sexta</option>
                                            <option value={2}>Sabado</option>
                                        </Select>
                                    </FormControl>
                                </Col>
                            )}
                        </Row>
                        <Divider />
                        <Typography variant={"h6"} style={{ paddingBottom: "1em" }}>
                            Adicionais do Grupo
                        </Typography>

                        {opcao.opcoes.map(opcaoA => (
                            <Row>
                                <Col xs={1} md={1} lg={1} className={classes.colOptions}>
                                    <TextField
                                        defaultValue={opcaoA.ordemExib}
                                        label="Posicao"
                                        fullWidth
                                        size={"small"}
                                        className={clsx(classes.margin, classes.textField)}
                                        onChange={e => {}}
                                        variant="outlined"
                                    />
                                </Col>
                                <Col xs={3} md={3} lg={3} className={classes.colOptions}>
                                    <TextField
                                        defaultValue={opcaoA.nome}
                                        label="Nome Opcao"
                                        fullWidth
                                        size={"small"}
                                        className={clsx(classes.margin, classes.textField)}
                                        onChange={e => {}}
                                        variant="outlined"
                                    />
                                </Col>
                                <Col xs={5} md={5} lg={5} className={classes.colOptions}>
                                    <TextField
                                        defaultValue={opcaoA.descricao}
                                        label="Descrição Opcao"
                                        fullWidth
                                        size={"small"}
                                        className={clsx(classes.margin, classes.textField)}
                                        onChange={e => {}}
                                        variant="outlined"
                                    />
                                </Col>
                                <Col xs={2} md={2} lg={2} className={classes.colOptions}>
                                    <TextField
                                        defaultValue={opcaoA.valor}
                                        label="Valor"
                                        // size="small"
                                        fullWidth
                                        size={"small"}
                                        className={clsx(classes.margin, classes.textField)}
                                        onChange={e => {
                                            changeValuePrice(e)
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">R$</InputAdornment>
                                            ),
                                        }}
                                        variant="outlined"
                                    />
                                </Col>
                                <Col xs={1} md={1} lg={1} className={classes.colOptions}>
                                    <Button
                                        className={classes.buttonAdicionarItem}
                                        // onClick={() => handleAdicionarProduto(indexC)}
                                    >
                                        <CloseIcon />
                                    </Button>
                                </Col>
                            </Row>
                        ))}

                        <Row>
                            <Col xs={12} md={12} lg={12} className={classes.colOptions}>
                                <Button
                                    className={classes.buttonAdicionarItem}
                                    // onClick={() => handleAdicionarProduto(indexC)}
                                    startIcon={<AddIcon />}
                                >
                                    Adicionar Item
                                </Button>
                            </Col>
                        </Row>
                    </TabPanel>
                ))}
            </div>
        </>
    )
}
