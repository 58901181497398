import api from "./api"
import { RegistrarLogErro } from "./clientefiel"

function tratamentoResponse(response, chamada) {
    if (response.data.codErro > 0)
        return { retornoErro: response.data.codErro, mensagem: response.data.mensagem }
    else if (typeof response.data == "string" && response.data != "")
        return {
            retornoErro: 1,
            mensagem: chamada + " - Erro inesperado, entre em contato com os reponsáveis.",
        }
    else return response.data
}

const ErroRequisicao = function (data, aplicativoDados) {
    try {
        console.log("ErroRequisicao> ", data.request, data.message)
        if (data.message == "Network Error") {
            return "Verifique sua conexão!"
            // return 'Sem Internet ou sistema fora do ar!'
        } else if (data.message) {
            return "Erro: " + data.message
        }
        return false
    } catch (error) {
        RegistrarLogErro(error, "ErroRequisicao", data)
    }
}

export const CadastrarProduto = async function (data, aplicativoDados) {
    try {
        const response = await api
            .post("produto/CadastrarProduto", data, {
                headers: { "Content-Type": "application/json" },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(error)
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.log("CadastrarProduto => ", response)

        return tratamentoResponse(response, "CadastrarProduto")
    } catch (error) {
        RegistrarLogErro(error, "CadastrarProduto", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}
export const CadastrarCategoriaProduto = async function (data, aplicativoDados) {
    try {
        const response = await api
            .post("produto/CadastrarCategoriaProduto", data, {
                headers: { "Content-Type": "application/json" },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(error)
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.log("CadastrarCategoriaProduto => ", response)

        return tratamentoResponse(response, "CadastrarCategoriaProduto")
    } catch (error) {
        RegistrarLogErro(error, "CadastrarCategoriaProduto", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

export const ObterCardapio = async function (Authorization, data) {
    try {
        data.token = `${process.env.REACT_APP_CLIENTEFIEL_TOKEN}`

        const response = await api.post("produto/ObterCardapio ", data, {
            headers: { "Content-Type": "application/json", Authorization: Authorization },
        })

        console.log("ObterCardapio  => ", response)

        if (response.data.codErro > 0)
            return { retornoErro: response.data.codErro, mensagem: response.data.mensagem }
        else if (typeof response.data == "string")
            return {
                retornoErro: 1,
                mensagem: "Erro inesperado, entre em contato com os reponsáveis.",
            }
        else return response.data
    } catch (error) {
        RegistrarLogErro(error, "ObterCardapio", data)

        return { retornoErro: 1, mensagem: error.message }
    }
}
