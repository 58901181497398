import TextField from "@material-ui/core/TextField"

import Select from "@material-ui/core/Select"
import { makeStyles, useTheme } from "@material-ui/core/styles"

import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import clsx from "clsx"

import { Col, Row } from "react-bootstrap"

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
        width: "100%",
    },
    rootcategoria: {
        // maxWidth: 345,
    },
    categoria: {
        margin: "0px!important",
        alignItems: "center",
    },
    ativo: {},
    inativo: {
        color: "#dc3545",
    },
    descricao: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        // width: "100%!important",
        display: "block",
        width: "95%",
    },
    margin: {
        // margin: theme.spacing(1),
        // padding: '10px',
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    },
    textField: {
        background: "#f9f9f9",
        // width: '7em',
    },
    padding: {
        padding: "10px",
    },
    listItem: {
        display: "flex",
        alignItems: "center",
    },
    fullSize: {
        padding: "4px 200px 4px 16px",
    },
    smallSize: {
        padding: "4px 4px 4px 16px",
    },
    colOptions: {
        display: "flex",
        margin: "0.5em 0px",
        justifyContent: "center",
        alignItems: "center",
    },
    buttonAdicionarCategoria: {
        // margin: theme.spacing(1),
        // background: '#dc3545',
        // color: 'white',f
        // position: 'relative',
        // float: 'right',
    },
    buttonListAdicionarItem: {
        justifyContent: "center",
    },
    buttonAdicionarItem: {
        color: "#dc3545",
    },
}))

function getMoney(str) {
    console.log("str", str)
    var value = str.replace(/[\D]+/g, "")
    if (value == "") {
        return value
    }
    return parseInt(value)
}
function formatReal(int) {
    if (int == "") {
        return "0"
    }

    console.log("int", int)
    var tmp = int + ""
    tmp = tmp.replace(/([0-9]{2})$/g, ",$1")
    if (tmp.length > 6) tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2")

    return tmp
}

const changeValuePrice = e => {
    var value = formatReal(getMoney(e.target.value))
    e.target.value = value
}

export default function ConfiguracoesIniciais(props) {
    const classes = useStyles()
    const theme = useTheme()

    const { categoria } = props

    return (
        <Row
        // style={{marginTop: '2em'}}
        >
            <Col xs={3} md={3} lg={3} className={classes.colOptions}>
                <TextField
                    defaultValue={categoria.ordemExib}
                    label="Posição"
                    id="categoria_ordem_exibicao"
                    // size="small"
                    fullWidth
                    className={clsx(classes.margin, classes.textField)}
                    onChange={e => {
                        // changeValuePrice(e)
                    }}
                    variant="outlined"
                />
            </Col>
            <Col xs={9} md={9} lg={9} className={classes.colOptions}>
                <TextField
                    defaultValue={categoria.nome}
                    label="Nome"
                    // size="small"
                    id="categoria_nome"
                    fullWidth
                    className={clsx(classes.margin, classes.textField)}
                    onChange={e => {
                        // changeValuePrice(e)
                    }}
                    variant="outlined"
                />
            </Col>

            <Col xs={12} md={4} lg={4} className={classes.colOptions}>
                <FormControl fullWidth variant="outlined" className={classes.formControl}>
                    <InputLabel htmlFor="diaDisponivel">Dia Disponível</InputLabel>
                    <Select
                        native
                        fullWidth
                        defaultValue={categoria.diaSemana}
                        label="Dia Disponível"
                        className={clsx(classes.margin, classes.textField)}
                        inputProps={{
                            name: "Dia Disponível",
                            id: "categoria_dia_disponivel",
                        }}
                    >
                        <option value={null}>Todos os dias</option>
                        <option value={"DOMINGO"}>Domingo</option>
                        <option value={"SEGUNDA"}>Segunda</option>
                        <option value={"TERCA"}>Terça</option>
                        <option value={"QUARTA"}>Quarta</option>
                        <option value={"QUINTA"}>Quinta</option>
                        <option value={"SEXTA"}>Sexta</option>
                        <option value={"SABADO"}>Sabado</option>
                    </Select>
                </FormControl>
            </Col>
            <Col xs={12} md={4} lg={4} className={classes.colOptions}>
                <FormControl fullWidth variant="outlined" className={classes.formControl}>
                    <InputLabel htmlFor="turnoDisponivel">Turno Disponível</InputLabel>
                    <Select
                        native
                        fullWidth
                        defaultValue={categoria.turnoProduto}
                        label="Turno Disponível"
                        className={clsx(classes.margin, classes.textField)}
                        inputProps={{
                            name: "Turno Disponível",
                            id: "categoria_turno_disponivel",
                        }}
                    >
                        <option value={null}>Diurno e Noturno</option>
                        <option value={"APENAS_DIURNO"}>Apenas Diurno</option>
                        <option value={"APENAS_NOTURNO"}>Apenas Noturno</option>
                    </Select>
                </FormControl>
            </Col>
            <Col xs={12} md={4} lg={4} className={classes.colOptions}>
                <FormControl fullWidth variant="outlined" className={classes.formControl}>
                    <InputLabel htmlFor="exibicao">Exibição</InputLabel>
                    <Select
                        native
                        fullWidth
                        defaultValue={categoria.exibicaoCategoriaEnum}
                        label="Exibição"
                        className={clsx(classes.margin, classes.textField)}
                        inputProps={{
                            name: "Exibição",
                            id: "categoria_exibicao",
                        }}
                    >
                        <option value={null}>Total</option>
                        <option value={"DELIVERY"}>Apenas Delivery</option>
                        <option value={"PEDIDO_MESA"}>Apenas Pedido Mesa</option>
                    </Select>
                </FormControl>
            </Col>
        </Row>
    )
}
