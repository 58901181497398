import {
    AppBar,
    Button,
    Card,
    CardActions,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Fab,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Toolbar,
    Typography,
} from "@material-ui/core"
import { Add, Close, Print } from "@material-ui/icons"
import { useContext, useState } from "react"

import { useHistory } from "react-router-dom"
import LoadingContext from "../../../contexts/loadingContext"
import ServicosContext from "../../../contexts/servicosContext"
import { RegistrarLogErro } from "../../../services/clientefiel"
import { mandarImprimir, textoHTML } from "../../../services/functionsGerais"
import { fecharmesa } from "../../../services/integracao"

const qz = require("qz-tray")

function CabecalhoMesa({
    classes,
    configuracoes,
    atualizarConfiguracoes,
    verificarSeTemQZ,
    mesa,
    handleClose,
    impressorasQZ,
}) {
    return (
        <div className={classes.containerCabecalho}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <div>
                        <IconButton color="inherit" onClick={handleClose} aria-label="close">
                            <Close />
                        </IconButton>
                        <Typography variant="h6" component="span">
                            Mesa {mesa?.numeroMesa}
                        </Typography>
                    </div>
                    <div>
                        {impressorasQZ.length > 0 ? (
                            <>
                                <FormControl
                                    variant="outlined"
                                    className={classes.formControl}
                                    fullWidth
                                >
                                    <InputLabel className={classes.opcaoImpressoraLabel}>
                                        Imprimir 1º Opção
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="Impressora-P-Imprimir"
                                        id="Impressora-P-Imprimir"
                                        defaultValue={
                                            configuracoes.printer.impressora
                                                ? configuracoes.printer.impressora
                                                : "vazio"
                                        }
                                        className={classes.selecaoImpressora}
                                        onChange={e => {
                                            configuracoes.printer.impressora = e.target.value
                                            localStorage.setItem(
                                                "configuracoes",
                                                JSON.stringify(configuracoes)
                                            )
                                            atualizarConfiguracoes(configuracoes)
                                        }}
                                        label="Impressora P/ Imprimir"
                                    >
                                        <MenuItem value="vazio">
                                            <em>Nenhuma</em>
                                        </MenuItem>
                                        {impressorasQZ.map((printer, index) => (
                                            <MenuItem key={index} value={printer}>
                                                {printer}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </>
                        ) : (
                            <div className={classes.botoesImpressoras}>
                                <Button
                                    onClick={e => {
                                        verificarSeTemQZ()
                                    }}
                                    variant="contained"
                                >
                                    Identificar Printer
                                </Button>
                                <Button
                                    onClick={e => {
                                        window.location.href =
                                            "https://clientefielsp.s3-sa-east-1.amazonaws.com/Cliente+Fiel+Printer.zip"
                                    }}
                                    variant="contained"
                                >
                                    Download Printer
                                </Button>
                            </div>
                        )}
                    </div>
                </Toolbar>
            </AppBar>
        </div>
    )
}

const DialogFinalizarMesa = ({
    mesa,
    openFinalizarMesa,
    setOpenFinalizarMesa,
    Transition,
    alertStart,
    useStyles,
}) => {
    const classes = useStyles()

    const { setLoading } = useContext(LoadingContext)

    const handleClose = () => setOpenFinalizarMesa(false)

    const fecharPedidoMesa = async mesa => {
        try {
            setLoading(true)

            const autorizacao = localStorage.getItem("chaveSistema")

            const response = await fecharmesa(autorizacao, mesa)

            if (response.retornoErro) {
                alertStart(response.mensagem, "error")
                setLoading(false)
                return false
            }
            alertStart(`Mesa ${mesa.numeroMesa} fechada com sucesso!`, "success")

            window.location.reload()

            setLoading(false)
        } catch (error) {
            RegistrarLogErro(error, "fecharPedidoMesa")
            alertStart("Procure os desenvolvedores! Erro: " + error.message, "error")
        }
    }

    return (
        <Dialog
            open={openFinalizarMesa}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
        >
            <DialogTitle>{`Fechar Mesa ${mesa?.numeroMesa}`}</DialogTitle>
            <DialogContent className={classes.listaPedidosImpressao}>
                {mesa?.pedidosMesa
                    ? mesa?.pedidosMesa.map((pedido, index) => (
                          <Button
                              fullWidth
                              onClick={() => {
                                  mandarImprimir(pedido?.textoImpressao, 1)
                              }}
                          >
                              <Print />
                              {index + 1}º Pedido{" "}
                              {Intl.NumberFormat("pt-BR", {
                                  style: "currency",
                                  currency: "BRL",
                              }).format(pedido.valorTotal)}
                          </Button>
                      ))
                    : null}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancelar</Button>
                <Button
                    onClick={() => {
                        mandarImprimir(mesa.impressao, 1)
                    }}
                    className={classes.botaoImprimir}
                >
                    Imprimir Todas
                </Button>

                <Button
                    onClick={() => {
                        fecharPedidoMesa(mesa)
                    }}
                    className={classes.botaoFecharMesa}
                >
                    Fechar Mesa
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const ListaPedidosMesa = ({
    mesa,
    setPedidoSelecionado,
    handleClose,
    useStyles,
    Transition,
    impressorasQZ,
    setImpressorasQZ,
    alertStart,
}) => {
    const classes = useStyles()
    const history = useHistory()

    const [openFinalizarMesa, setOpenFinalizarMesa] = useState(false)

    const { configuracoes, atualizarConfiguracoes } = useContext(ServicosContext)

    const handleSelecaoPedido = e => {
        if (!mesa.pedidosMesa) return

        const pedido = mesa.pedidosMesa[e.target.dataset.index]
        setPedidoSelecionado(pedido)

        textoHTML(pedido.textoImpressao)
    }

    const tipoPainelImpressao =
        impressorasQZ.length > 0
            ? "plugin" //qz
            : window.navigator.userAgent.includes("Electron")
            ? "software" //exe
            : "navegador" //padrao do navegador

    const verificarSeTemQZ = () => {
        try {
            if (qz.websocket.isActive()) {
                buscarImpressoras()
            } else {
                qz.websocket
                    .connect()
                    .then(() => {
                        qz.printers
                            .details()
                            .then(data => {
                                for (var i = 0; i < data.length; i++) {
                                    console.log(
                                        data[i].name +
                                            " " +
                                            data[i].driver +
                                            " " +
                                            data[i].density +
                                            " " +
                                            data[i].trays
                                    )
                                }
                            })
                            .catch(function (e) {
                                console.error(e)
                            })

                        //console.log('Conectado QZ!');
                        buscarImpressoras()
                    })
                    .catch(err => {
                        console.error("Erro QZ", err.message)
                        // alertStart("Impressao automatica não identifica!", "error")
                        if (tipoPainelImpressao === "plugin") {
                            setImpressorasQZ([])
                        }
                    })
            }
        } catch (error) {
            RegistrarLogErro(error, "verificarSeTemQZ")
        }
    }

    const buscarImpressoras = async () => {
        try {
            if (!configuracoes.printer?.impressora) {
                configuracoes.printer.impressora = await qz.printers.getDefault()
                atualizarConfiguracoes(configuracoes)
                // localStorage.setItem("configuracoes", JSON.stringify(configuracoes))
            }

            const printers = await qz.printers.find()
            setImpressorasQZ(printers)
            //console.log('PRINTERS QZ >>>>>>> ', printers)
        } catch (error) {
            // RegistrarLogErro(error, "buscarImpressoras")
        }
    }

    return (
        <Dialog
            TransitionComponent={Transition}
            fullScreen
            open={
                !!mesa?.numeroMesa
                // true
            }
            onClose={handleClose}
        >
            <CabecalhoMesa
                classes={classes}
                configuracoes={configuracoes}
                atualizarConfiguracoes={atualizarConfiguracoes}
                verificarSeTemQZ={verificarSeTemQZ}
                mesa={mesa}
                handleClose={handleClose}
                impressorasQZ={impressorasQZ}
            />
            <Grid container spacing={2} style={{ padding: "10px" }}>
                {mesa?.pedidosMesa
                    ? mesa?.pedidosMesa.map((pedido, index) => (
                          <Grid item key={index} xs={2}>
                              <Card
                                  className={classes.cartaoMesa}
                                  data-index={index}
                                  onClick={handleSelecaoPedido}
                              >
                                  <CardContent
                                      className={classes.cartaoMesaDados}
                                      style={{ pointerEvents: "none" }}
                                  >
                                      <Typography variant="h2" component="span">
                                          {index + 1}º
                                      </Typography>
                                      <div className={classes.dadosPedidoMesa_container}>
                                          <Typography
                                              className={classes.dadosPedidoMesa}
                                              variant="body2"
                                              color="textSecondary"
                                              component="span"
                                          >
                                              {pedido.strDataRealizacao}
                                          </Typography>
                                          <Typography
                                              className={classes.dadosPedidoMesa}
                                              component="span"
                                          >
                                              {pedido.cliente?.nome}
                                          </Typography>
                                          <Typography
                                              className={classes.dadosPedidoMesa}
                                              variant="body2"
                                              component="span"
                                          >
                                              {Intl.NumberFormat("pt-BR", {
                                                  style: "currency",
                                                  currency: "BRL",
                                              }).format(pedido.valorTotal)}
                                          </Typography>
                                      </div>
                                  </CardContent>
                                  <CardActions className={classes.cartaoMesaDesconto}>
                                      <Typography
                                          variant="button"
                                          align="center"
                                          className={[
                                              classes.cartaoMesaDesconto_texto,
                                              pedido.valorDesconto
                                                  ? classes.valorDesconto
                                                  : classes.semDesconto,
                                          ].join(" ")}
                                      >
                                          {pedido.valorDesconto
                                              ? `DESCONTO DE ${Intl.NumberFormat("pt-BR", {
                                                    style: "currency",
                                                    currency: "BRL",
                                                }).format(pedido.valorDesconto)}`
                                              : `SEM DESCONTO`}
                                      </Typography>
                                  </CardActions>
                              </Card>
                          </Grid>
                      ))
                    : null}
            </Grid>
            <Fab
                variant="extended"
                aria-label="add"
                className={classes.botaoEstaticoClose}
                // className={classes.fixedMesa}
                onClick={() => setOpenFinalizarMesa(true)}
            >
                <Close />
                Fechar Mesa
            </Fab>
            <Fab
                variant="extended"
                aria-label="add"
                className={classes.botaoEstaticoAdd}
                // className={classes.fixedMesa}
                onClick={() =>
                    history.push("/servicos/modulo/registrador-de-pedidos?mesa=" + mesa.numeroMesa)
                }
            >
                <Add />
                Adicionar Pedido
            </Fab>
            <DialogFinalizarMesa
                mesa={mesa}
                handleClose={handleClose}
                openFinalizarMesa={openFinalizarMesa}
                setOpenFinalizarMesa={setOpenFinalizarMesa}
                Transition={Transition}
                alertStart={alertStart}
                useStyles={useStyles}
            />
        </Dialog>
    )
}

export default ListaPedidosMesa
