import TextField from "@material-ui/core/TextField"

import { makeStyles, useTheme } from "@material-ui/core/styles"

import Typography from "@material-ui/core/Typography"

import InputAdornment from "@material-ui/core/InputAdornment"
import clsx from "clsx"

import { Col, Row } from "react-bootstrap"

import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
        width: "100%",
    },
    rootProduto: {
        // maxWidth: 345,
    },
    categoria: {
        margin: "0px!important",
        alignItems: "center",
    },
    ativo: {},
    inativo: {
        color: "#dc3545",
    },
    descricao: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        // width: "100%!important",
        display: "block",
        width: "95%",
    },
    margin: {
        // margin: theme.spacing(1),
        // padding: '10px',
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    },
    textField: {
        background: "#f9f9f9",
        // width: '7em',
    },
    padding: {
        padding: "10px",
    },
    listItem: {
        display: "flex",
        alignItems: "center",
    },
    fullSize: {
        padding: "4px 200px 4px 16px",
    },
    smallSize: {
        padding: "4px 4px 4px 16px",
    },
    colOptions: {
        display: "flex",
        margin: "0.5em 0px",
        justifyContent: "center",
        alignItems: "center",
    },
    buttonAdicionarCategoria: {
        // margin: theme.spacing(1),
        // background: '#dc3545',
        // color: 'white',f
        // position: 'relative',
        // float: 'right',
    },
    buttonListAdicionarItem: {
        justifyContent: "center",
    },
    buttonAdicionarItem: {
        color: "#dc3545",
    },
}))

function getMoney(str) {
    console.log("str", str)
    var value = str.replace(/[\D]+/g, "")
    if (value == "") {
        return value
    }
    return parseInt(value)
}
function formatReal(int) {
    if (int == "") {
        return "0"
    }

    console.log("int", int)
    var tmp = int + ""
    tmp = tmp.replace(/([0-9]{2})$/g, ",$1")
    if (tmp.length > 6) tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2")

    return tmp
}

const changeValuePrice = e => {
    var value = formatReal(getMoney(e.target.value))
    e.target.value = value
}

const DisponibilidadeEValor = props => {
    const { dia, classes } = props

    return (
        <Col xs={12} md={4} lg={4} style={{ margin: "1em 0" }}>
            <Typography variant={"h6"}>{dia.nome}:</Typography>
            <div style={{ display: "flex" }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            style={{ padding: "0px" }}
                            defaultChecked={dia.disponivel}
                            color="primary"
                            inputProps={{ "aria-label": " checkbox" }}
                        />
                    }
                    labelPlacement={"top"}
                    label={"Disponível"}
                />
                <TextField
                    defaultValue={dia.valor}
                    label="Valor"
                    fullWidth
                    className={clsx(classes.margin, classes.textField)}
                    onChange={e => {
                        changeValuePrice(e)
                    }}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                    }}
                    variant="outlined"
                />
            </div>
        </Col>
    )
}

export default function Disponibilidade(props) {
    const classes = useStyles()
    const theme = useTheme()

    const { produto, setProduto } = props

    const dias = [
        {
            nome: "Domingo",
            valor: produto.valor,
            disponivel: false,
        },
        {
            nome: "Segunda",
            valor: produto.valor,
            disponivel: false,
        },
        {
            nome: "Terça",
            valor: produto.valor,
            disponivel: false,
        },
        {
            nome: "Quarta",
            valor: produto.valor,
            disponivel: false,
        },
        {
            nome: "Quinta",
            valor: produto.valor,
            disponivel: false,
        },
        {
            nome: "Sexta",
            valor: produto.valor,
            disponivel: false,
        },
        {
            nome: "Sabado",
            valor: produto.valor,
            disponivel: false,
        },
    ]

    return (
        <Row style={{ marginTop: "1em" }}>
            {dias.map(dia => (
                <DisponibilidadeEValor dia={dia} classes={classes} />
            ))}
        </Row>
    )
}
