import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import { DropzoneArea, DropzoneDialog } from "material-ui-dropzone"
import { Component } from "react"

import { makeStyles, useTheme } from "@material-ui/core/styles"

import InputAdornment from "@material-ui/core/InputAdornment"
import CloseIcon from "@material-ui/icons/Close"
import clsx from "clsx"

import { Col, Row } from "react-bootstrap"

import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
        width: "100%",
    },
    rootProduto: {
        // maxWidth: 345,
    },
    categoria: {
        margin: "0px!important",
        alignItems: "center",
    },
    ativo: {},
    inativo: {
        color: "#dc3545",
    },
    descricao: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        // width: "100%!important",
        display: "block",
        width: "95%",
    },
    margin: {
        // margin: theme.spacing(1),
        // padding: '10px',
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    },
    textField: {
        background: "#f9f9f9",
        // width: '7em',
    },
    padding: {
        padding: "10px",
    },
    listItem: {
        display: "flex",
        alignItems: "center",
    },
    fullSize: {
        padding: "4px 200px 4px 16px",
    },
    smallSize: {
        padding: "4px 4px 4px 16px",
    },
    colOptions: {
        display: "flex",
        margin: "0.5em 0px",
        justifyContent: "center",
        alignItems: "center",
    },
    buttonAdicionarCategoria: {
        // margin: theme.spacing(1),
        // background: '#dc3545',
        // color: 'white',f
        // position: 'relative',
        // float: 'right',
    },
    buttonListAdicionarItem: {
        justifyContent: "center",
    },
    buttonAdicionarItem: {
        color: "#dc3545",
    },
}))

function getMoney(str) {
    console.log("str", str)
    var value = str.replace(/[\D]+/g, "")
    if (value == "") {
        return value
    }
    return parseInt(value)
}
function formatReal(int) {
    if (int == "") {
        return "0"
    }

    console.log("int", int)
    var tmp = int + ""
    tmp = tmp.replace(/([0-9]{2})$/g, ",$1")
    if (tmp.length > 6) tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2")

    return tmp
}

const changeValuePrice = e => {
    var value = formatReal(getMoney(e.target.value))
    e.target.value = value
}

class DropzoneAreaExample extends Component {
    constructor(props) {
        super(props)
        this.state = {
            files: [],
        }
    }
    handleChange(files) {
        console.log("files", files)
        this.setState({
            files: files[0],
        })
    }
    render() {
        return (
            <DropzoneArea
                dropzoneText={"Adicione uma foto do produto"}
                filesLimit={1}
                onChange={this.handleChange.bind(this)}
            />
        )
    }
}

class DropzoneDialogExample extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            files: [],
        }
    }

    handleClose() {
        this.setState({
            open: false,
        })
    }

    handleSave(files) {
        //Saving files to state for further use and closing Modal.
        this.setState({
            files: files,
            open: false,
        })
    }

    handleOpen() {
        this.setState({
            open: true,
        })
    }

    render() {
        return (
            <div>
                <Button onClick={this.handleOpen.bind(this)}>Add Image</Button>
                <DropzoneDialog
                    open={this.state.open}
                    onSave={this.handleSave.bind(this)}
                    acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
                    showPreviews={true}
                    maxFileSize={5000000}
                    onClose={this.handleClose.bind(this)}
                />
            </div>
        )
    }
}

export default function ConfiguracoesIniciais(props) {
    const classes = useStyles()
    const theme = useTheme()

    const { produto, setProduto, modoEdicao } = props

    return (
        <Row style={{ marginTop: "2em" }}>
            <Col xs={12} md={4} lg={4} className={classes.colOptions}>
                {/* <DropzoneDialogExample/> */}

                {produto.urlImagem ? (
                    <Row>
                        <Col xs={12} md={12} lg={12} className={classes.colOptions}>
                            <Button
                                className={classes.buttonAdicionarItem}
                                // onClick={() => handleAdicionarProduto(indexC)}
                                startIcon={<CloseIcon />}
                            >
                                Remover Foto
                            </Button>
                        </Col>
                        <Col xs={12} md={12} lg={12} className={classes.colOptions}>
                            <img src={produto.urlImagem} />
                        </Col>
                    </Row>
                ) : (
                    <DropzoneAreaExample />
                )}
            </Col>
            <Col xs={12} md={8} lg={8} className={classes.colOptions}>
                <Row>
                    <Col xs={1} md={1} lg={1} className={classes.colOptions}>
                        <TextField
                            defaultValue={produto.ordemExib}
                            label="Posição"
                            // size="small"
                            fullWidth
                            className={clsx(classes.margin, classes.textField)}
                            onChange={e => {
                                // changeValuePrice(e)
                            }}
                            variant="outlined"
                        />
                    </Col>
                    <Col xs={7} md={4} lg={7} className={classes.colOptions}>
                        <TextField
                            defaultValue={produto.nome}
                            label="Nome"
                            // size="small"
                            fullWidth
                            className={clsx(classes.margin, classes.textField)}
                            onChange={e => {
                                // changeValuePrice(e)
                            }}
                            variant="outlined"
                        />
                    </Col>
                    <Col xs={4} md={4} lg={4} className={classes.colOptions}>
                        <TextField
                            defaultValue={produto.valor}
                            label="Valor"
                            // size="small"
                            fullWidth
                            className={clsx(classes.margin, classes.textField)}
                            onChange={e => {
                                changeValuePrice(e)
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">R$</InputAdornment>
                                ),
                            }}
                            variant="outlined"
                        />
                    </Col>
                    <Col xs={12} md={6} lg={6} className={classes.colOptions}>
                        <TextField
                            defaultValue={produto.descricao}
                            multiline={true}
                            label="Descrição"
                            // size="small"
                            fullWidth
                            className={clsx(classes.margin, classes.textField)}
                            onChange={e => {
                                // changeValuePrice(e)
                            }}
                            variant="outlined"
                        />
                    </Col>
                    {(modoEdicao == "avancado" || modoEdicao == "pdv") && (
                        <Col xs={12} md={3} lg={3} className={classes.colOptions}>
                            <TextField
                                defaultValue={produto.codigoPdv}
                                label="Codigo PDV"
                                // size="small"
                                fullWidth
                                className={clsx(classes.margin, classes.textField)}
                                onChange={e => {
                                    // changeValuePrice(e)
                                }}
                                variant="outlined"
                            />
                        </Col>
                    )}
                    {modoEdicao == "avancado" && (
                        <Col xs={12} md={3} lg={3} className={classes.colOptions}>
                            <TextField
                                defaultValue={produto.pontos}
                                label="Pontos"
                                // size="small"
                                fullWidth
                                className={clsx(classes.margin, classes.textField)}
                                onChange={e => {
                                    // changeValuePrice(e)
                                }}
                                variant="outlined"
                            />
                        </Col>
                    )}
                    {modoEdicao == "avancado" && (
                        <Col xs={12} md={12} lg={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        defaultChecked={true}
                                        color="primary"
                                        inputProps={{ "aria-label": " checkbox" }}
                                    />
                                }
                                label="Produto apenas informativo"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        defaultChecked
                                        color="primary"
                                        inputProps={{ "aria-label": " checkbox" }}
                                    />
                                }
                                label="Bloquear descontos percentuais"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        defaultChecked
                                        color="primary"
                                        inputProps={{ "aria-label": " checkbox" }}
                                    />
                                }
                                label="Disponível apenas na primeira compra"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        defaultChecked
                                        color="primary"
                                        inputProps={{ "aria-label": " checkbox" }}
                                    />
                                }
                                label="Permitie observação"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        defaultChecked
                                        color="primary"
                                        inputProps={{ "aria-label": " checkbox" }}
                                    />
                                }
                                label="Pontua no programa de fidelidade"
                            />
                        </Col>
                    )}
                </Row>
            </Col>
        </Row>
    )
}
