import React, { useContext, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"

import { useSnackbar } from "notistack"
import RegistradorContext from "../../../../contexts/registradorContext"
import ServicosContext from "../../../../contexts/servicosContext"

import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles"
import MotorcycleIcon from "@material-ui/icons/Motorcycle"
import CheckIcon from "@material-ui/icons/Check"
import Backdrop from "@material-ui/core/Backdrop"
import Radio from "@material-ui/core/Radio"
import AlertFixo from "@material-ui/lab/Alert"
import PhoneIcon from "@material-ui/icons/Phone"
import EditIcon from "@material-ui/icons/Edit"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import RadioGroup from "@material-ui/core/RadioGroup"
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber"
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import AirlineSeatReclineNormalIcon from "@material-ui/icons/AirlineSeatReclineNormal"
import NativeSelect from "@material-ui/core/NativeSelect"
import PrintIcon from "@material-ui/icons/Print"
import TransferWithinAStationIcon from "@material-ui/icons/TransferWithinAStation"
import PaymentIcon from "@material-ui/icons/Payment"
import LocationOnIcon from "@material-ui/icons/LocationOn"
import CardActions from "@material-ui/core/CardActions"
import SwapHorizIcon from "@material-ui/icons/SwapHoriz"
import InputAdornment from "@material-ui/core/InputAdornment"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import ButtonGroup from "@material-ui/core/ButtonGroup"
import InputBase from "@material-ui/core/InputBase"
import AddIcon from "@material-ui/icons/Add"
import RemoveIcon from "@material-ui/icons/Remove"
import FormLabel from "@material-ui/core/FormLabel"
import FormGroup from "@material-ui/core/FormGroup"
import Skeleton from "@material-ui/lab/Skeleton"
import FormControl from "@material-ui/core/FormControl"
import Stepper from "@material-ui/core/Stepper"
import Step from "@material-ui/core/Step"
import StepLabel from "@material-ui/core/StepLabel"
import ArrowRightIcon from "@material-ui/icons/ArrowRight"
import CircularProgress from "@material-ui/core/CircularProgress"
import AppBar from "@material-ui/core/AppBar"
import Button from "@material-ui/core/Button"
import CardActionArea from "@material-ui/core/CardActionArea"
import Container from "@material-ui/core/Container"
import Dialog from "@material-ui/core/Dialog"
import IconButton from "@material-ui/core/IconButton"
import Slide from "@material-ui/core/Slide"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import CloseIcon from "@material-ui/icons/Close"
import { Card, Col, Row } from "react-bootstrap"

import {
    AcessoPorEnderecoEstabelecimentos,
    ListarEstabelecimentosPorEndereco,
    RegistrarLogErro,
} from "../../../../services/clientefiel"

import { calcularValorTotal } from "../../../../pages/RegistradorDePedidos/functions"
import {
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Menu,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    TextField,
} from "@material-ui/core"

import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api"
import usePlacesAutocomplete, { getGeocode } from "use-places-autocomplete"
import { useRef } from "react"
import { AtualizarEnderecoCliente } from "../../../../services/cliente"

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

const useStyles = makeStyles(theme => ({
    fixed: {
        position: "fixed",
        right: "2em",
        bottom: "2em",
        zIndex: 1000,
    },
    fixedMesa: {
        position: "fixed",
        right: "2em",
        bottom: "2em",
        zIndex: 2000,
    },
    fixedMesaFechar: {
        position: "fixed",
        right: "2em",
        bottom: "7em",
        zIndex: 2000,
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    rootAlert: {
        width: "100%",
        "& > * + *": {
            marginTop: theme.spacing(2),
        },
    },
    backdrop: {
        // zIndex: theme.zIndex.drawer + 999999,
        color: "#fff",
    },
    pos: {
        marginBottom: 12,
    },
    heading: {
        width: "100%",
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    rootList: {
        width: "100%!important",
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: "inline",
        width: "100%",
    },
    appBar: {
        position: "fixed",
    },
    appContainer: {
        paddingTop: "6em",
        backgroundColor: "#f4f4f4",
        // height: "100%",
        // minHeight: "-webkit-fill-available",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    list: {
        width: 250,
    },
    fullList: {
        width: "auto",
    },
}))

const libraries = ["places"]

const GooglePlacesAutocomplete = ({ setEnderecoSelecionado }) => {
    const {
        ready,
        value,
        setValue,
        suggestions: { status, data },
        clearSuggestions,
    } = usePlacesAutocomplete({
        // requestOptions: //TODO: Adicionar bias para localização https://developers.google.com/maps/documentation/javascript/reference/places-service?hl=pt-br#LocationBias
    })

    const handleSearch = e => {
        try {
            if (!e.target) return

            setValue(e.target.value)
        } catch (err) {
            console.error(err)
        }
    }

    const handleSelect = async end => {
        setValue(end, false)
        clearSuggestions()

        const response = await getGeocode({ address: end })
        const endereco = response[0]

        setEnderecoSelecionado(prev => ({
            ...prev,
            logradouro: endereco.address_components.find(addressComponent =>
                addressComponent.types.includes("route")
            )?.long_name,
            numero: endereco.address_components.find(addressComponent =>
                addressComponent.types.includes("street_number")
            )?.long_name,
            bairro: endereco.address_components.find(addressComponent =>
                addressComponent.types.includes("sublocality_level_1")
            )?.long_name,
            localidade: endereco.address_components.find(addressComponent =>
                addressComponent.types.includes("administrative_area_level_2")
            )?.long_name,
            uf: endereco.address_components.find(addressComponent =>
                addressComponent.types.includes("administrative_area_level_1")
            )?.short_name,
            cep: endereco.address_components.find(addressComponent =>
                addressComponent.types.includes("postal_code")
            )?.short_name,
            latitude: endereco.geometry.location.lat(),
            longitude: endereco.geometry.location.lng(),
        }))
    }

    return (
        <div>
            <TextField
                value={value}
                onChange={handleSearch}
                disabled={!ready}
                id="places-input"
                placeholder="Digite o endereço"
                variant="outlined"
                fullWidth
            />
            <Paper style={{ position: "absolute", zIndex: 10000 }}>
                <MenuList>
                    {data.map(({ place_id, description }) => (
                        <MenuItem
                            key={place_id}
                            value={description}
                            button
                            divider
                            onClick={e => handleSelect(description)}
                        >
                            {description}
                        </MenuItem>
                    ))}
                </MenuList>
            </Paper>
        </div>
    )
}

const CardEndereco = props => {
    const classes = useStyles()

    const { configuracoes } = useContext(ServicosContext)

    const usarGoogleMapsAPI = !JSON.parse(localStorage.getItem("aplicativoCF"))?.tipoSelecaoEndereco

    const apiKey = "AIzaSyAr6pWr6cj7IZ931BnL84KgCiYinmmf35A"
    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: apiKey,
        version: "beta",
        libraries,
        language: "pt-br",
    })

    const [enderecoSelecionado, setEnderecoSelecionado] = useState({
        ...props.endereco,
        bairroEspecifico: configuracoes.user.parametros.tipoEntregaBairro === 1,
    })

    const temCoordenada =
        (Number(configuracoes.user.parametros.estabelecimento.latitude) &&
            Number(configuracoes.user.parametros.estabelecimento.longitude)) ||
        (enderecoSelecionado.latitude && enderecoSelecionado.longitude)

    const mapCenter = enderecoSelecionado.latitude
        ? { lat: enderecoSelecionado.latitude, lng: enderecoSelecionado.longitude }
        : temCoordenada
        ? {
              lat: Number(configuracoes.user.parametros.estabelecimento.latitude),
              lng: Number(configuracoes.user.parametros.estabelecimento.longitude),
          }
        : { lat: -12.720151, lng: -50.43879 }

    const handleClick = () => props.handleSelecionarEndereco(props.endereco, props.index)

    const handleSalvar = async () => {
        const enderecoEditado = {
            id: props.endereco.id,
            appNome: configuracoes.user.parametros.appNome,
            idCliente: props.idCliente,
            uf: props.endereco.uf,
            cidade: document.getElementById("endereco-cidade").value,
            bairro: document.getElementById("endereco-bairro").value,
            logradouro: document.getElementById("endereco-logradouro").value,
            numero: document.getElementById("endereco-numero").value,
            complemento: document.getElementById("endereco-complemento").value,
            referencia: document.getElementById("endereco-referencia").value,
            cep: document.getElementById("endereco-cep").value,
            token: "a~I?;;+_@!AL^",
        }

        const enderecoAtualizado = await AtualizarEnderecoCliente(
            props.autorizacao,
            enderecoEditado
        )

        props.endereco.id = enderecoAtualizado.id
        props.endereco.cidade = enderecoAtualizado.cidade
        props.endereco.bairro = enderecoAtualizado.bairro
        props.endereco.logradouro = enderecoAtualizado.logradouro
        props.endereco.numero = enderecoAtualizado.numero
        props.endereco.complemento = enderecoAtualizado.complemento
        props.endereco.referencia = enderecoAtualizado.referencia
        props.endereco.cep = enderecoAtualizado.cep

        setEnderecoSelecionado(prev => ({ ...prev, ...enderecoAtualizado }))

        setAbrirEdicao(false)
    }

    const handleContinuar = () => {
        setAbrirMapa(false)
        setAbrirEdicao(true)

        document.getElementById("endereco-cidade").value = enderecoSelecionado.cidade
        document.getElementById("endereco-bairro").value = enderecoSelecionado.bairro
        document.getElementById("endereco-logradouro").value = enderecoSelecionado.logradouro
        document.getElementById("endereco-numero").value = enderecoSelecionado.numero
        document.getElementById("endereco-complemento").value = enderecoSelecionado.complemento
        document.getElementById("endereco-referencia").value = enderecoSelecionado.referencia
        document.getElementById("endereco-cep").value = enderecoSelecionado.cep
    }

    const [abrirEdicao, setAbrirEdicao] = useState(false)
    const [abrirMapa, setAbrirMapa] = useState(false)

    console.log("end teste", enderecoSelecionado)

    return (
        <Col xs={12} md={6} lg={4} key={props.index} style={{ marginBottom: "0.5em" }}>
            <Card className={classes.root} style={{ height: "100%" }}>
                <CardActionArea
                    onClick={handleClick}
                    style={{
                        flexFlow: "column-reverse",
                        alignItems: "normal",
                    }}
                >
                    <Row
                        style={{
                            margin: 0,
                            textAlign: "",
                            width: "100%",
                            padding: "1em",
                            alignItems: "center",
                        }}
                    >
                        <Col
                            xs={2}
                            md={2}
                            lg={2}
                            style={{
                                padding: 0,
                                textAlign: "center",
                                fontSize: "2em",
                            }}
                        >
                            <LocationOnIcon />
                        </Col>
                        <Col xs={10} md={10} lg={10}>
                            <Typography variant="body2" color="textSecondary" component="p">
                                {props.endereco.bairro} - {props.endereco.cidade}
                            </Typography>
                            <Typography gutterBottom component="h2">
                                {props.endereco.logradouro} - {props.endereco.numero}
                            </Typography>
                        </Col>
                    </Row>
                    <IconButton
                        size="small"
                        style={{ position: "absolute", right: "0.25rem", top: "0.25rem" }}
                        onClick={e => {
                            e.stopPropagation()
                            setAbrirEdicao(true)
                        }}
                    >
                        <EditIcon />
                    </IconButton>
                </CardActionArea>
            </Card>
            <Dialog
                open={abrirEdicao}
                onClose={() => setAbrirEdicao(false)}
                fullWidth
                maxWidth={"md"}
            >
                <DialogTitle>
                    <IconButton className="setaVoltarModal" onClick={() => setAbrirEdicao(false)}>
                        <ArrowForwardIosIcon />
                    </IconButton>
                    Editar Endereço
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="endereco-cidade"
                                variant="outlined"
                                label="Cidade"
                                defaultValue={enderecoSelecionado.cidade}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="endereco-bairro"
                                variant="outlined"
                                label="Bairro"
                                defaultValue={enderecoSelecionado.bairro}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="endereco-logradouro"
                                variant="outlined"
                                label="Rua"
                                defaultValue={enderecoSelecionado.logradouro}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="endereco-numero"
                                variant="outlined"
                                label="Número"
                                defaultValue={enderecoSelecionado.numero}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="endereco-complemento"
                                variant="outlined"
                                label="Complemento"
                                defaultValue={enderecoSelecionado.complemento}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="endereco-referencia"
                                variant="outlined"
                                label="Referência"
                                defaultValue={enderecoSelecionado.referencia}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="endereco-cep"
                                variant="outlined"
                                label="CEP"
                                defaultValue={enderecoSelecionado.cep}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {usarGoogleMapsAPI ? (
                        <Button
                            style={{ backgroundColor: "#28a745", color: "white" }}
                            onClick={() => setAbrirMapa(true)}
                        >
                            Usar mapa
                        </Button>
                    ) : null}
                    <Button
                        style={{ backgroundColor: "#28a745", color: "white" }}
                        onClick={handleSalvar}
                    >
                        Salvar
                    </Button>
                </DialogActions>
            </Dialog>
            {usarGoogleMapsAPI && isLoaded ? (
                <Dialog
                    open={abrirMapa}
                    onClose={() => setAbrirMapa(false)}
                    fullWidth
                    maxWidth="md"
                >
                    <DialogTitle>
                        <IconButton className="setaVoltarModal" onClick={() => setAbrirMapa(false)}>
                            <ArrowForwardIosIcon />
                        </IconButton>
                        Editar Endereço
                    </DialogTitle>
                    <DialogContent>
                        <div>
                            <div id="places-autocomplete-container">
                                <GooglePlacesAutocomplete
                                    setEnderecoSelecionado={setEnderecoSelecionado}
                                />
                            </div>
                            <GoogleMap
                                id="google-map"
                                mapContainerStyle={{
                                    width: "100%",
                                    height: "320px",
                                    margin: "1rem auto",
                                }}
                                center={mapCenter}
                                zoom={temCoordenada ? 16 : 4}
                            >
                                <Marker position={mapCenter} />
                                {enderecoSelecionado.latitude ? (
                                    <Marker
                                        position={{
                                            lat: enderecoSelecionado.latitude,
                                            lng: enderecoSelecionado.longitude,
                                        }}
                                    />
                                ) : null}
                            </GoogleMap>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            style={{ backgroundColor: "#28a745", color: "white" }}
                            onClick={handleContinuar}
                        >
                            Continuar
                        </Button>
                    </DialogActions>
                </Dialog>
            ) : null}
        </Col>
    )
}

export default function Index() {
    const autorizacao = localStorage.getItem("chaveSistema")
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
    )
    const classes = useStyles()
    const theme = useTheme()
    const history = useHistory()

    const {
        carrinho,
        listaRepeticao,
        ativoMinimoEntregaGratis,
        informacoesParaBuscarCardapio,
        setCarrinho,
        listaEnderecos,
        loadingEnderecos,

        setLoadingEstabelecimentos,
        estabelecimentosDisponiveis,
        setListaEstabelecimentosDisponiveis,
        setOpenEstabelecimentoDisponiveis,
        setInformacoesParaBuscarCardapio,
        handleBuscarCardapio,
        setOpenCadastroEndereco,
        openCadastroEndereco,
        openListaEnderecos,
        enderecoRetiradaNoLocal,
        handleSelecionarEstabelecimento,
        setListaEnderecos,
        setOpenListaEnderecos,
        isDelivery,
        valorDescontoManual,
    } = useContext(RegistradorContext)

    const { enqueueSnackbar } = useSnackbar()

    const { configuracoes } = useContext(ServicosContext)

    const alertStart = (msg, tipo) => {
        enqueueSnackbar(msg, { variant: tipo })
    }

    const [acessoEnderecoGuardado, setAcessoEnderecoGuardado] = useState()

    const handleCloseEndereco = () => {
        try {
            document.getElementById("procurar-cliente").value = ""
            setOpenListaEnderecos(false)
            setListaEnderecos([])
        } catch (error) {
            RegistrarLogErro(error, "handleCloseEndereco")
            alertStart("Procure os desenvolvedores! Erro: " + error.message, "error")
        }
    }

    const handleRetiradaNoLocal = async () => {
        try {
            console.log("||> handleRetiradaNoLocal")

            setLoadingEstabelecimentos(true)
            setOpenEstabelecimentoDisponiveis(true)
            const cart = Object.assign({}, carrinho)
            cart.status = 99
            cart.tipoPedido = 1
            cart.end = enderecoRetiradaNoLocal()
            cart.appNome = configuracoes.user?.parametros?.appNome
            cart.end.appNome = configuracoes.user?.parametros?.appNome

            const response = await AcessoPorEnderecoEstabelecimentos(autorizacao, cart.end)
            if (response.retornoErro) {
                alertStart(response.mensagem, "error")
                return false
            }
            console.log("response", response)
            console.log("estabelecimentosDisponiveis", estabelecimentosDisponiveis)
            const estabelecimentosDoUsuario = response.estabelecimentos.filter(e => {
                return estabelecimentosDisponiveis.filter(ee => ee?.id == e?.id).length
            })

            const estabelecimentos = estabelecimentosDoUsuario.filter(
                loja => loja.permiteRetiradaBalcao === true
            )

            // const estabelecimentos = response.estabelecimentos.filter(loja => loja.permiteRetiradaBalcao === true)
            setCarrinho(cart)

            if (estabelecimentos.length === 1) {
                handleSelecionarEstabelecimento(estabelecimentos[0], cart)
            }

            setListaEstabelecimentosDisponiveis(estabelecimentos)
            setLoadingEstabelecimentos(false)
        } catch (error) {
            RegistrarLogErro(error, "handleRetiradaNoLocal")
            alertStart("Procure os desenvolvedores! Erro: " + error.message, "error")
        }
    }

    async function getAcessoPorEndereco(endereco) {
        try {
            const expirationTime = Date.now() + 300000
            localStorage.setItem("expiration-acesso-endereco", expirationTime)

            const response = await AcessoPorEnderecoEstabelecimentos(autorizacao, endereco)
            setAcessoEnderecoGuardado(response)

            return response
        } catch (error) {
            console.error(error)
            RegistrarLogErro(error, "getAcessoPorEndereco")
            alertStart("Procure os desenvolvedores! Erro: " + error.message, "error")
        }
    }

    const handleClienteNaLoja = async () => {
        try {
            console.log("||> handleClienteNaLoja")
            setLoadingEstabelecimentos(true)
            setOpenEstabelecimentoDisponiveis(true)
            const cart = Object.assign({}, carrinho)
            cart.status = 99
            cart.tipoPedido = 2 //mesa
            cart.end = enderecoRetiradaNoLocal()

            cart.appNome = configuracoes.user?.parametros?.appNome
            cart.end.appNome = configuracoes.user?.parametros?.appNome

            let response = null

            const isAcessoPorEnderecoExpirado =
                !acessoEnderecoGuardado ||
                !localStorage.getItem("expiration-acesso-endereco") ||
                Date.now() > localStorage.getItem("expiration-acesso-endereco")
            if (isAcessoPorEnderecoExpirado) response = await getAcessoPorEndereco(cart.end)
            else response = acessoEnderecoGuardado

            if (response.retornoErro) {
                alertStart(response.mensagem, "error")
                return false
            }

            const estabelecimentosDoUsuario = response.estabelecimentos.filter(e => {
                return estabelecimentosDisponiveis.filter(ee => ee?.id == e?.id).length
            })

            const estabelecimentos = estabelecimentosDoUsuario.filter(
                loja => loja.permiteRetiradaBalcao === true
            )

            // const estabelecimentos = response.estabelecimentos.filter(loja => loja.permiteRetiradaBalcao === true)
            setCarrinho(cart)

            if (estabelecimentos.length === 1) {
                handleSelecionarEstabelecimento(estabelecimentos[0], cart)
            }

            setListaEstabelecimentosDisponiveis(estabelecimentos)
            setLoadingEstabelecimentos(false)
        } catch (error) {
            RegistrarLogErro(error, "handleClienteNaLoja")
            alertStart("Procure os desenvolvedores! Erro: " + error.message, "error")
        }
    }

    const handleSelecionarEndereco = async (endereco, index) => {
        try {
            endereco.appNome = configuracoes.user?.parametros?.appNome
            console.log("||> handleSelecionarEndereco", endereco)
            setInformacoesParaBuscarCardapio({
                ...informacoesParaBuscarCardapio,
                idEndereco: endereco.id,
            })
            setOpenEstabelecimentoDisponiveis(true)
            setLoadingEstabelecimentos(true)
            endereco.token = "a~I?;;+_@!AL^"

            if (configuracoes.user.parametros.tipoEntregaBairro == 1) {
                endereco.bairroEspecifico = 1
            } else {
                endereco.bairroEspecifico = 0
            }

            const response = await ListarEstabelecimentosPorEndereco(autorizacao, endereco)
            console.log("response", response)

            if (response.retornoErro) {
                alertStart(response.mensagem, "error")
                return false
            }

            const estabelecimentosDoUsuario = response.filter(e => {
                return estabelecimentosDisponiveis.filter(ee => ee?.id == e?.id).length
            })

            const estabelecimentos = estabelecimentosDoUsuario.filter(
                loja => loja.possuiEnderecoAtendido === true
            )

            const cart = Object.assign({}, carrinho)
            cart.end = endereco

            console.log("cart", cart)

            cart.tipoPedido = 0
            listaEnderecos[index] = endereco

            setCarrinho(cart)
            if (estabelecimentos.length === 1) {
                handleSelecionarEstabelecimento(estabelecimentos[0], cart)
            }

            setListaEstabelecimentosDisponiveis(estabelecimentos)
            setLoadingEstabelecimentos(false)
        } catch (error) {
            RegistrarLogErro(error, "selecionouBairroSelect")
            alertStart("Procure os desenvolvedores! Erro: " + error.message, "error")
        }
    }

    useEffect(() => {
        const params = new URLSearchParams(window.location.search)
        const numeroMesa = params.get("mesa")

        if (numeroMesa && estabelecimentosDisponiveis) {
            handleClienteNaLoja()
        }
    }, [estabelecimentosDisponiveis])

    return openListaEnderecos ? (
        <Dialog
            fullScreen
            open={openListaEnderecos}
            onClose={handleCloseEndereco}
            TransitionComponent={Transition}
        >
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleCloseEndereco}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        {carrinho?.cliente?.id ? (
                            <>
                                {!loadingEnderecos ? "(" + listaEnderecos.length + ")" : null}{" "}
                                Endereços {mobile ? "" : "Encontrados"}
                            </>
                        ) : (
                            <>Opções de Entrega</>
                        )}
                    </Typography>
                    {carrinho?.cliente?.id ? (
                        <Button
                            color="inherit"
                            onClick={() => {
                                setOpenCadastroEndereco(true)
                                console.log("openCadastroEndereco", openCadastroEndereco)
                            }}
                        >
                            Cadastrar {mobile ? "" : "Endereço"}
                        </Button>
                    ) : null}
                </Toolbar>
            </AppBar>

            <Container className={classes.appContainer}>
                <Row>
                    {loadingEnderecos ? (
                        <>
                            <Col xs={12} md={12} lg={12} style={{ marginBottom: "0.5em" }}>
                                <Skeleton
                                    variant="rect"
                                    width={"100%"}
                                    height={48}
                                    style={{ marginBottom: "1em" }}
                                />
                            </Col>
                            <Col xs={12} md={6} lg={6} style={{ marginBottom: "0.5em" }}>
                                <Skeleton variant="rect" width={"100%"} height={77} />
                            </Col>
                            <Col xs={12} md={6} lg={6} style={{ marginBottom: "0.5em" }}>
                                <Skeleton variant="rect" width={"100%"} height={77} />
                            </Col>
                        </>
                    ) : (
                        <>
                            <Col xs={12} md={12} lg={12} style={{ marginBottom: "0.5em" }}>
                                <AlertFixo
                                    severity="warning"
                                    style={{ width: "100%", marginBottom: "1em" }}
                                >
                                    {carrinho?.cliente?.id ? (
                                        <>
                                            Você buscou endereços do usuário{" "}
                                            <b>{carrinho.cliente?.nome}</b>.
                                        </>
                                    ) : (
                                        <>
                                            Cliente <b>anônimo</b> não é possivel cadastrar
                                            endereço.
                                        </>
                                    )}
                                </AlertFixo>
                            </Col>
                            <Col xs={12} md={6} lg={6} style={{ marginBottom: "0.5em" }}>
                                <Card className={classes.root} style={{ height: "100%" }}>
                                    <CardActionArea
                                        onClick={handleRetiradaNoLocal}
                                        style={{ flexFlow: "column-reverse", alignItems: "normal" }}
                                    >
                                        <Row
                                            style={{
                                                margin: 0,
                                                textAlign: "",
                                                width: "100%",
                                                padding: "1em",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Col
                                                xs={2}
                                                md={2}
                                                lg={2}
                                                style={{
                                                    padding: 0,
                                                    textAlign: "center",
                                                    fontSize: "2em",
                                                }}
                                            >
                                                <TransferWithinAStationIcon></TransferWithinAStationIcon>
                                            </Col>
                                            <Col xs={10} md={10} lg={10}>
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                    component="p"
                                                >
                                                    Pedido Para
                                                </Typography>
                                                <Typography gutterBottom component="h2">
                                                    Retirar No Local
                                                </Typography>
                                            </Col>
                                        </Row>
                                    </CardActionArea>
                                </Card>
                            </Col>
                            <Col xs={12} md={6} lg={6} style={{ marginBottom: "0.5em" }}>
                                <Card className={classes.root} style={{ height: "100%" }}>
                                    <CardActionArea
                                        onClick={handleClienteNaLoja}
                                        style={{ flexFlow: "column-reverse", alignItems: "normal" }}
                                    >
                                        <Row
                                            style={{
                                                margin: 0,
                                                textAlign: "",
                                                width: "100%",
                                                padding: "1em",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Col
                                                xs={2}
                                                md={2}
                                                lg={2}
                                                style={{
                                                    padding: 0,
                                                    textAlign: "center",
                                                    fontSize: "2em",
                                                }}
                                            >
                                                <AirlineSeatReclineNormalIcon></AirlineSeatReclineNormalIcon>
                                            </Col>
                                            <Col xs={10} md={10} lg={10}>
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                    component="p"
                                                >
                                                    Pedido Para
                                                </Typography>
                                                <Typography gutterBottom component="h2">
                                                    Cliente Na Mesa
                                                </Typography>
                                            </Col>
                                        </Row>
                                    </CardActionArea>
                                </Card>
                            </Col>
                        </>
                    )}

                    {loadingEnderecos ? (
                        <>
                            {listaRepeticao.map(chave => (
                                <Col
                                    xs={12}
                                    md={6}
                                    lg={4}
                                    key={chave}
                                    style={{ marginBottom: "0.5em" }}
                                >
                                    <Skeleton variant="rect" width={"100%"} height={77} />
                                </Col>
                            ))}
                        </>
                    ) : (
                        <>
                            {listaEnderecos.length ? (
                                listaEnderecos.map((endereco, index) => (
                                    <CardEndereco
                                        endereco={endereco}
                                        index={index}
                                        handleSelecionarEndereco={handleSelecionarEndereco}
                                        idCliente={carrinho?.cliente?.id}
                                        autorizacao={autorizacao}
                                    />
                                ))
                            ) : carrinho?.cliente?.id ? (
                                <Col
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    style={{ textAlign: "center", marginBottom: "0.5em" }}
                                >
                                    <Typography>Nenhum endereço foi encontrado.</Typography>
                                </Col>
                            ) : null}
                        </>
                    )}
                </Row>
            </Container>
        </Dialog>
    ) : (
        <React.Fragment></React.Fragment>
    )
}
