import { RegistrarLogErro } from "./clientefiel"

function removeNull(obj) {
    return Object.fromEntries(
        Object.entries(obj)
            .filter(([_, value]) => value != null)
            .map(([key, value]) => [key, value === Object(value) ? removeNull(value) : value])
    )
}

export const iniciouLive = al => {
    const hoje = new Date()
    const { inicioDivulgacao, fimDivulgacao, dataRealizacaoInicio } = al

    if (
        dataRealizacaoInicio <= hoje.getTime() &&
        dataRealizacaoInicio + 3600000 >= hoje.getTime()
    ) {
        return `ENTRAR`
    }

    if (
        new Date(dataRealizacaoInicio).getDate() === hoje.getDate() &&
        dataRealizacaoInicio + 3600000 > hoje.getTime()
    ) {
        return `HOJE ${al.inicio ? `${al.inicio}hrs` : ""}`
    }

    if (dataRealizacaoInicio <= hoje.getTime()) {
        return "ACESSAR"
    }

    return formatarData(dataRealizacaoInicio)
}

const meses = ["JAN", "FEV", "MAR", "ABR", "MAI", "JUN", "JUL", "AGO", "SET", "OUT", "NOV", "DEZ"]

export const dataFormatada = data => {
    return `${transformaDia(data)}/${transformaMes(data)}/${data.getFullYear()}`
}

const transformaDia = function (data) {
    const dia = new Date(data).getDate()
    const diaF = dia.length > 1 ? "0" + dia : dia

    return diaF
}

const transformaMes = function (data) {
    let mes = new Date(data).getMonth()
    mes = meses[mes]
    return mes.toLowerCase()
}

export function tratarResposta(response, metodo) {
    if (response.data) {
        return {
            response: response.data.response,
            message: response.data.message,
            status: response.status,
            statusText: response.statusText,
        }
    } else {
        const data = {
            response: [],
            message: `${metodo} não foi possivel, tire uma foto desse erro e avise para os responsáveis. Status ${1000}: ${
                response.message
            }`,
            status: 1000,
            statusText: `error`,
        }
        return data
    }
}

export function parseReal(valor) {
    return Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(valor)
}

function verificarConfigAntiga(configs) {
    const configuracoes_aceitarPedidos = JSON.parse(
        localStorage.getItem("configuracoes_aceitarPedidos")
    )
    if (configuracoes_aceitarPedidos) {
        configs.user.parametros = configuracoes_aceitarPedidos.parametros
        configs.user.perfil = configuracoes_aceitarPedidos.perfil
        configs.user.id = configuracoes_aceitarPedidos.id
        configs.user.usuario = configuracoes_aceitarPedidos.usuario
    }
    localStorage.removeItem("configuracoes_aceitarPedidos")
    return configs
}

export function getMoney(str) {
    var tmp = str.replace(/[\D]+/g, "")
    return parseInt(tmp === "" ? 0 : tmp)
}

export function formatReal(int) {
    var tmp = int + ""
    tmp = tmp.replace(/([0-9]{2})$/g, ",$1")
    if (tmp.length > 6) tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2")

    return tmp
}

export function criarConfig() {
    let configs = {
        printer: {
            tamanhoFonteImpressao: 14,
            tamanhoFonte: 100,
            agruparViasImpressao: 1,
            tipoImpressao: "",
            impressora: null,
            impressora2: null,
        },
        recebedor: {
            exibicaoMaxima: 200,
            aceitarPedidoAutomatico: false,
            imprimir: true,
            buscarAutomaticamenteXSegundos: 180,
            efeitoSonoro: true,
        },
        user: {
            id: null,
            perfil: null,
            usuario: null,
            parametros: {},
        },
        corSitePrimaria: "#E63B2C",
        corSiteSecundaria: "#464646",
    }

    configs = verificarConfigAntiga(configs)

    console.log("atualizarConfiguracoes 1", configs)
    localStorage.setItem("configs", JSON.stringify(removeNull(configs)))

    return configs
}

export function verificarConfigsParametros(parametros) {
    let configs = JSON.parse(localStorage.getItem("configs"))
    if (!configs) {
        configs = {
            printer: {
                tamanhoFonteImpressao: 14,
                tamanhoFonte: 100,
                agruparViasImpressao: 1,
                tipoImpressao: "",
                impressora: null,
                impressora2: null,
            },
            recebedor: {
                exibicaoMaxima: 200,
                aceitarPedidoAutomatico: false,
                imprimir: true,
                buscarAutomaticamenteXSegundos: 180,
                efeitoSonoro: true,
            },
            corSitePrimaria: parametros.corPrimaria,
            corSiteSecundaria: parametros.corSecundaria,
        }
    }

    configs.user.parametros = parametros

    console.log("atualizarConfiguracoes 2", configs)
    localStorage.setItem("configs", JSON.stringify(removeNull(configs)))

    return configs
}

export function verificarConfigsUsuario(usuario) {
    console.log("verificarConfigsUsuario", usuario)
    let configs = JSON.parse(localStorage.getItem("configs"))
    if (!configs) {
        configs = {
            printer: {
                tamanhoFonteImpressao: 14,
                tamanhoFonte: 100,
                agruparViasImpressao: 1,
                tipoImpressao: "",
                impressora: null,
                impressora2: null,
            },
            recebedor: {
                exibicaoMaxima: 200,
                aceitarPedidoAutomatico: false,
                imprimir: false,
                buscarAutomaticamenteXSegundos: 180,
                efeitoSonoro: true,
            },
            corSitePrimaria: configs?.corSitePrimaria ? configs?.corSitePrimaria : "#E63B2C",
            corSiteSecundaria: configs?.corSiteSecundaria ? configs?.corSiteSecundaria : "#464646",
        }
    }

    configs.user = {
        id: usuario.id,
        perfil: usuario.perfil,
        usuario: usuario.email,
        parametros: {},
    }

    console.log("atualizarConfiguracoes 3", configs)
    localStorage.setItem("configs", JSON.stringify(removeNull(configs)))
    console.log("verificarCOnfigsusuario", configs)
}

export function FormataStringData(data, inicio = true) {
    var dia = data.split("/")[0]
    var mes = data.split("/")[1]
    var ano = data.split("/")[2]

    return ano + "-" + ("0" + mes).slice(-2) + "-" + ("0" + dia).slice(-2) + inicio
        ? " 00:00:00"
        : " 23:59:59"
}

export const formatarData = data => {
    data = new Date(data)
    return data.toISOString().substr(0, 10).split("-").reverse().join("/")
}

export function mandarImprimir(texto, qtd) {
    try {
        document.getElementById("rootPrint").innerHTML = texto.replace(/(?:\r\n|\r|\n)/g, "<br>")

        window.setTimeout(function () {
            window.print()
        }, 500)
    } catch (error) {
        RegistrarLogErro(error, "mandarImprimir", { texto, qtd })
    }
}

// TODO: Substituir todos que usam por DialogInformacoesPedido
export const textoHTML = texto => {
    window.setTimeout(function () {
        try {
            document.getElementById("textoHTML").innerHTML = texto
                .replace(/(?:\r\n|\r|\n)/g, "<br>")
                .replace("<br>", "")
                .replace("<br>", "")
                .replace("<br>", "")
        } catch (error) {}
    }, 500)
}

export function removeAcento(text) {
    try {
        text = text.toLowerCase()
        text = text.replace(new RegExp("[ÁÀÂÃ]", "gi"), "a")
        text = text.replace(new RegExp("[ÉÈÊ]", "gi"), "e")
        text = text.replace(new RegExp("[ÍÌÎ]", "gi"), "i")
        text = text.replace(new RegExp("[ÓÒÔÕ]", "gi"), "o")
        text = text.replace(new RegExp("[ÚÙÛ]", "gi"), "u")
        text = text.replace(new RegExp("[Ç]", "gi"), "c")
        return text
    } catch (error) {
        RegistrarLogErro(error, "removeAcento", text)
    }
}
